import React from 'react'
import { Row, Col, Drawer } from 'antd';
import { CustomerDetailsWrapper} from '../../container/agent/style';

const ViewEmployee = ({showDetails,setShowDetails,setPassData,...props}) => {

    const employeeDetails = props.parentData;
    console.log(employeeDetails);
    const onClose = () => {
        setShowDetails(false);
        setPassData({
            parentData : ''
        })
    }

    return (
        <>
            <Drawer title="Employee Details" onClose={onClose} open={showDetails} bodyStyle={{ paddingBottom: 80, }} placement='top'>
                <CustomerDetailsWrapper>
                    <Row gutter={25}>
                        <Col xxl={24} xl={24} xs={24}>
                            <div className="about-project">
                                <Row gutter={25}>
                                    {employeeDetails?.employee_id && (
                                    <Col xxl={4} xl={6}  lg={6} xs={24}>
                                        <span> Employee ID </span>
                                        <p>{employeeDetails?.employee_id ? employeeDetails.employee_id : '-' }</p>
                                    </Col>
                                    )}
                                    {employeeDetails?.name && (
                                    <Col xxl={4} xl={6}  lg={6} xs={24}>
                                        <span> Employee Name</span>
                                        <p>{employeeDetails?.name ? employeeDetails.name : '-' }</p>
                                    </Col>
                                    )}
                                    {employeeDetails?.mobile && (
                                    <Col xxl={4} xl={6}  lg={6}  xs={24}>
                                        <span> Mobile Number </span>
                                        <p className="color-primary">{employeeDetails?.mobile ? employeeDetails.mobile : '-' }</p>
                                    </Col>
                                    )}
                                    {employeeDetails?.email && (
                                    <Col xxl={4}  xl={6}  lg={6}  xs={24}>
                                        <span>Email</span>
                                        <p>{employeeDetails?.email  ? employeeDetails.email : '-' }</p>
                                    </Col>
                                    )}

                                    {employeeDetails?.branch?.name && (
                                    <Col xxl={4} xl={6}  lg={6}  xs={24}>
                                        <span>Branch</span>
                                        <p >{employeeDetails?.branch?.name ? employeeDetails.branch?.name : '-' }</p>
                                    </Col>
                                    )}
                                    
                                    {employeeDetails?.employee_category?.name && (
                                        <Col xxl={4} xl={6}  lg={6}  xs={24}>
                                            <span>Category</span>
                                            <p>{employeeDetails?.employee_category?.name ? employeeDetails.employee_category.name : '-' }</p>
                                        </Col>
                                    )}
                                    
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </CustomerDetailsWrapper>
            </Drawer>
        </>
    )
}

export default ViewEmployee