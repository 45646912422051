import React, { Suspense, useState, useEffect, useCallback } from 'react';
import { Row, Col, Table,  Pagination, Spin, Switch, notification, Modal  } from 'antd'; 
import FeatherIcon from 'feather-icons-react';

import { PageHeader } from '../../components/page-headers/page-headers';
import { Button } from '../../components/buttons/buttons';
import { Main, TableWrapper,  TablePagination,TableHeaderSearch } from '../styled';
import { useSelector,useDispatch } from 'react-redux';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { employeeFetchData, updateEmployeeLoginStatus, manualEmployeeSync } from '../../redux/employee/actionCreator'; //
// import { useHistory } from 'react-router-dom';
import ChangePassword  from '../../components/employee/changePassword';
import ChangePermission from '../../components/employee/changePermission';
import ViewDetails from '../../components/employee/viewDetails';
import { capitalize } from '../../utility/localStorageControl';

import { agentForceLogout, resetAgentForceLogout } from '../../redux/customercare/actionCreator';


const EmployeeList = () => {
    
    const dispatch = useDispatch();
    
    const [data,setData] = useState({
        'page': 1,
        'page_items_count': 50,
        'type':'pai',
        'search':'',
        'status':1,
    });

    useEffect(() => {
        if (employeeFetchData) {
            dispatch(employeeFetchData(data));
        }
    }, [dispatch,data]);
    
    const employee = useSelector((state) => state.employee.data);

    const [tableData,setTableData] = useState([]);

    const handleSearch = (searchText) => {
        console.log(searchText);
        setData({...data,search:searchText,page:1});
    }


    const [showDetails,setShowDetails] = useState(false);
    const [showPermission,setShowPermission] = useState(false);
	const [showPassword,setShowPassword] = useState(false);

    const [passData,setPassData] = useState({
		parentData : '',
		orderData:''
	})
    
	const showDrawer = (data,parent) => {
		console.log(data);
        setPassData({parentData:parent})
        if(data === 'view')
            setShowDetails(true) 
    	if(data === 'edit')
            setShowPermission(true) 
		if(data === 'password')
            setShowPassword(true) 
        
	}
      
    const columns = [
        {
            title: 'Employee ID',
            dataIndex: 'employeeId',
            key: 'employeeId',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.employeeId <  b.employeeId ? -1 : 1,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Employee Name',
            dataIndex: 'employeeName',
            key: 'employeeName',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile'
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            key: 'branch'
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            sorter: (a, b) => a.category <  b.category ? -1 : 1,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'User Type',
            dataIndex: 'userType',
            key: 'userType'
        },
        {
            title:'Is Login',
            dataIndex: 'is_login',
            key: 'is_login',
            render: (is_login, record) => (
                record.status === true ? 
                <Switch
                  checked={is_login}
                  onChange={(checked) => userAllowLogin(checked, record.key)}
                  checkedChildren="Active" unCheckedChildren="Block"
                /> : ''
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => (
                // <Switch
                //   checked={status}
                //   onChange={(checked) => userStatus(checked, record.key)}
                //   checkedChildren="Active" unCheckedChildren="Block"
                // />
                status === true ? <span className={`status-text active`}>{'Active'}</span> : <span className={`status-text blocked`}>{'Block'}</span>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 200,
        },
    ];

    const handleForceLogout = useCallback((value) => {
        console.log(value);
        Modal.confirm({
            title: 'Are you sure you want to log out?',
            content: value?.name,
            okText: 'Submit',
            cancelText: 'Cancel',
            onOk: () => {
                dispatch(agentForceLogout({ user_id: value?.id, user_type: value?.user_type }));
            },
            onCancel: () => {},
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            ),
        });
    }, [dispatch]);


    const agentForceLogoutDetails  =  useSelector((state) => state.agentForceLogout);
    useEffect(() => {
        if(agentForceLogoutDetails?.data === 'updated' && agentForceLogoutDetails?.loading === false){
            Modal.info({
                title: 'Logout Submitted Successfully',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    console.log('OK button clicked');
                    dispatch(resetAgentForceLogout());
                  // Add your custom action here
                },
            });
        }

        if(agentForceLogoutDetails?.error  && agentForceLogoutDetails?.loading === false){
            Modal.error({
                title: 'Please try Again',
                okText: 'Ok',
                footer: null, // Disable the default footer
                onOk() {
                    console.log('OK button clicked');
                    dispatch(resetAgentForceLogout());
                  // Add your custom action here
                },
            });
        }
    },[agentForceLogoutDetails, dispatch])

    useEffect(() => {
        const dataTable = [];
        if (employee && employee.object_list ){
            employee.object_list.map((value) => {
                const { id, email,  employee_id, is_login,mobile,name,user_type,status,branch,employee_category } = value;
                return dataTable.push({
                    key: id,
                    employeeId: employee_id,
                    employeeName: capitalize(name),
                    mobile: mobile,
                    email: email,
                    category: employee_category?.name,
                    branch:branch?.name+' - '+branch?.code,
                    userType:capitalize(user_type),
                    is_login : is_login,
                    status: status,
                    action: (
                        <div className="table-actions">
                            
                                <Button className="btn-icon" type="primary" shape="circle" title="View Employee" onClick={() => showDrawer('view',value)}>
                                    <FeatherIcon icon="eye" size={16} /> 
                                </Button>
                                <Button className="btn-icon" type="info" shape="circle" title="Edit Employee"  onClick={() => showDrawer('edit',value)}>
                                    <FeatherIcon icon="edit" size={16} /> 
                                </Button>
                                <Button className="btn-icon" type="info" shape="circle" title="Change Password" onClick={() => showDrawer('password',value)}>
                                    <FeatherIcon icon="lock" size={16} /> 
                                </Button>
                                { user_type ?
                                <Button className="btn-icon" type="primary" shape="circle" title="Logout" onClick={() => handleForceLogout(value)} >
                                    <FeatherIcon icon="log-out" size={16} /> 
                                </Button>
                                : ''}
                            
                        </div>
                    ),
                });
            });
        }
        if(dataTable.length > 0){
            setTableData(dataTable);
        }
    },[employee, handleForceLogout])
   
    
    const userAllowLogin = (checked,id) => {
        dispatch(updateEmployeeLoginStatus({employee_id:id,is_login_status:checked}));
    }

    const employeeLoginStatus =  useSelector((state) => state.employeeLoginStatus);
    useEffect(() => {
        if(employeeLoginStatus.data?.id){
            notification.success({
                message: 'Submitted Successfully.....',
            });
            setTableData((prevData) =>
                prevData.map((item) =>
                    item.key === employeeLoginStatus.data.id ? { ...item, is_login: employeeLoginStatus.data.checked } : item
                )
            );
        }
    },[employeeLoginStatus]);

    
    // const userStatus = (checked,id) => {
    //     console.log(checked)
    //     console.log(id)
    //     dispatch(updateEmployeeStatus({employee_id:id,status:checked}));
    // }

    const employeeStatus =  useSelector((state) => state.employeeStatus);
    useEffect(() => {
        console.log(employeeStatus)
        if(employeeStatus.data?.id){
            notification.success({
                message: 'Submitted Successfully.....',
            });
            setTableData((prevData) =>
                prevData.map((item) =>
                    item.key === employeeStatus.data.id ? { ...item, status: employeeStatus.data.checked } : item
                )
            );
        }
    },[employeeStatus]);

 
    const onChangePagination =(page,pageSize) => {
        setData({ ...data, page:page, page_items_count:pageSize,})
    }

    const onChangePageSize =(current, size) => {
        setData({ ...data,  page:1, page_items_count:size})
    }

    const [manualSync,setManualSync] = useState(false);
    const manualSyncEmployee = () => {
        setManualSync(true);
        dispatch(manualEmployeeSync());
        notification.success({
            message: 'Employee details start syncing with SAP.',
        });
    }

    return (
        <>
            <PageHeader
                ghost
                title="Employee List"
                buttons={[
                    <TableHeaderSearch key={1}>
                        <div className="table-header-search-bar">
                            <div className="table-header-search-search">
                                <AutoComplete onSearch={handleSearch} placeholder="Search Employee" patterns />
                            </div>
                            <div key="2" className="page-header-actions">
                                <Button type="secondary" outlined size={'large'} style={{float: 'right'}} onClick={() => manualSyncEmployee()}  disabled={manualSync}>
                                    <img src={require('../../static/img/icon/New Customer.svg').default} alt="" /> Sync Employee Details with SAP
                                </Button>
                            </div>
                        </div>
                        
                    </TableHeaderSearch>
                ]}
            />
            <Main>
                <Suspense
                    fallback={
                        <Spin />
                    }
                >
                { employee?.object_list &&  employee?.object_list.length > 0 && (
                    <Row gutter={25}>
                        <Col sm={24} md={24}>
                            <TableWrapper className="table-responsive">
                                <Table  columns={columns} pagination={false} dataSource={tableData} scroll={{ y: 570 }} sticky />
                            </TableWrapper>
                        </Col>
                        <Col xs={24} className="mt-30"></Col>
                        <Col xs={24} >
                            <TablePagination>
                                <Pagination defaultCurrent={employee.current_page} total={employee.total_page*data.page_items_count} pageSize={data.page_items_count} pageSizeOptions={['50','100','200',]} onChange={onChangePagination} onShowSizeChange={onChangePageSize} />
                            </TablePagination>
                        </Col>
                    </Row>
                )}
                </Suspense>
                
                { showDetails && (
                    <ViewDetails showDetails={showDetails} setShowDetails={setShowDetails} {...passData} setPassData={setPassData} />
                )}

                { showPermission && (
                    <ChangePermission showPermission={showPermission} setShowPermission={setShowPermission} {...passData} setPassData={setPassData} />
                )}

                { showPassword && (
                    <ChangePassword showPassword={showPassword} setShowPassword={setShowPassword} {...passData} setPassData={setPassData} />
                )}

            </Main>
        </>
    )
}

export default EmployeeList