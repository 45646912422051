import actions from './actions';


const { 
    AGENT_STATUS_DATA_BEGIN, AGENT_STATUS_DATA_SUCCESS, AGENT_STATUS_DATA_ERR, 
    AGENT_UPDATE_STATUS_DATA_BEGIN, AGENT_UPDATE_STATUS_DATA_SUCCESS, AGENT_UPDATE_STATUS_DATA_ERR, 
    AGENT_UPDATE_CALL_STATUS_DATA_BEGIN, AGENT_UPDATE_CALL_STATUS_DATA_SUCCESS, AGENT_UPDATE_CALL_STATUS_DATA_ERR, 
    AGENT_CLICK_TO_CALL_STATUS_DATA_BEGIN, AGENT_CLICK_TO_CALL_STATUS_DATA_SUCCESS, AGENT_CLICK_TO_CALL_STATUS_DATA_ERR,
    AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_BEGIN, AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_SUCCESS, AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_ERR, 
    AGENT_CHANGE_CALL_STATUS_DATA_BEGIN,AGENT_CHANGE_CALL_STATUS_DATA_SUCCESS,AGENT_CHANGE_CALL_STATUS_DATA_ERR,AGENT_CHANGE_CALL_STATUS_DATA_RESET,
    AGENT_SUBMIT_PURCHASE_DATA_BEGIN, AGENT_SUBMIT_PURCHASE_DATA_SUCCESS, AGENT_SUBMIT_PURCHASE_DATA_ERR, 
    AGENT_RESUBMIT_PURCHASE_DATA_BEGIN, AGENT_RESUBMIT_PURCHASE_DATA_SUCCESS, AGENT_RESUBMIT_PURCHASE_DATA_ERR, 
    AGENT_LIST_DATA_BEGIN, AGENT_LIST_DATA_SUCCESS,AGENT_LIST_DATA_ERR, 
    AGENT_WALKOUT_QUERIES_DATA_BEGIN,AGENT_WALKOUT_QUERIES_DATA_SUCCESS,AGENT_WALKOUT_QUERIES_DATA_ERR,
    AGENT_PURCHASE_QUERIES_DATA_BEGIN,AGENT_PURCHASE_QUERIES_DATA_SUCCESS,AGENT_PURCHASE_QUERIES_DATA_ERR,
    AGENT_PURCHASE_EDIT_DATA_BEGIN,AGENT_PURCHASE_EDIT_DATA_SUCCESS,AGENT_PURCHASE_EDIT_DATA_ERR,
    AGENT_NUMBER_DATA_BEGIN, AGENT_NUMBER_DATA_SUCCESS, AGENT_NUMBER_DATA_ERR, 
    DISPOSITION_REQUEST_TYPE_DATA_BEGIN, DISPOSITION_REQUEST_TYPE_DATA_SUCCESS, DISPOSITION_REQUEST_TYPE_DATA_ERR,
    AGENT_SUBMIT_REGISTER_DATA_BEGIN, AGENT_SUBMIT_REGISTER_DATA_SUCCESS, AGENT_SUBMIT_REGISTER_DATA_ERR, 
    GET_REQUEST_TYPE_BEGIN, GET_REQUEST_TYPE_SUCCESS, GET_REQUEST_TYPE_ERR, 
    REQUEST_SUBMITTED_LIST_BEGIN, REQUEST_SUBMITTED_LIST_SUCCESS, REQUEST_SUBMITTED_LIST_ERR, 
    REQUEST_SUBMITTED_DETAIL_BEGIN, REQUEST_SUBMITTED_DETAIL_SUCCESS, REQUEST_SUBMITTED_DETAIL_ERR, 
    HAPPY_COMPLAINT_LIST_BEGIN, HAPPY_COMPLAINT_LIST_SUCCESS, HAPPY_COMPLAINT_LIST_ERR,
    UPDATE_COMPLAINT_ID_BEGIN, UPDATE_COMPLAINT_ID_SUCCESS, UPDATE_COMPLAINT_ID_ERR,UPDATE_COMPLAINT_ID_RESET,
    UPDATE_PRIORITY_COMPLAINT_ID_BEGIN, UPDATE_PRIORITY_COMPLAINT_ID_SUCCESS, UPDATE_PRIORITY_COMPLAINT_ID_ERR,UPDATE_PRIORITY_COMPLAINT_ID_RESET,
    UPDATE_PRIORITY_TO_SCHEDULED_BEGIN, UPDATE_PRIORITY_TO_SCHEDULED_SUCCESS, UPDATE_PRIORITY_TO_SCHEDULED_ERR,UPDATE_PRIORITY_TO_SCHEDULED_RESET,
    GET_REGISTER_DISPOSITION_LIST_BEGIN, GET_REGISTER_DISPOSITION_LIST_SUCCESS,GET_REGISTER_DISPOSITION_LIST_ERR, 
    UPDATE_REGISTER_STATUS_BEGIN, UPDATE_REGISTER_STATUS_SUCCESS, UPDATE_REGISTER_STATUS_ERR, UPDATE_REGISTER_STATUS_RESET,
    GET_PRIORITY_LIST_BEGIN, GET_PRIORITY_LIST_SUCCESS, GET_PRIORITY_LIST_ERR, 
    GET_SCHEDULE_LIST_BEGIN, GET_SCHEDULE_LIST_SUCCESS, GET_SCHEDULE_LIST_ERR,  
    GET_LEGAL_SCHEDULE_LIST_BEGIN, GET_LEGAL_SCHEDULE_LIST_SUCCESS, GET_LEGAL_SCHEDULE_LIST_ERR,  
    GET_DEMO_REGISTER_LIST_BEGIN, GET_DEMO_REGISTER_LIST_SUCCESS, GET_DEMO_REGISTER_LIST_ERR,
   
    GET_ABANDONED_LIST_BEGIN, GET_ABANDONED_LIST_SUCCESS, GET_ABANDONED_LIST_ERR,
    GET_ABANDONED_DISPOSITION_LIST_BEGIN, GET_ABANDONED_DISPOSITION_LIST_SUCCESS, GET_ABANDONED_DISPOSITION_LIST_ERR,
    UPDATE_ABANDONED_STATUS_BEGIN, UPDATE_ABANDONED_STATUS_SUCCESS,UPDATE_ABANDONED_STATUS_ERR, UPDATE_ABANDONED_STATUS_RESET,


    GET_MANDATORY_LIST_BEGIN, GET_MANDATORY_LIST_SUCCESS, GET_MANDATORY_LIST_ERR,
    GET_MANDATORY_DISPOSITION_LIST_BEGIN, GET_MANDATORY_DISPOSITION_LIST_SUCCESS, GET_MANDATORY_DISPOSITION_LIST_ERR,
    UPDATE_MANDATORY_STATUS_BEGIN, UPDATE_MANDATORY_STATUS_SUCCESS, UPDATE_MANDATORY_STATUS_ERR, UPDATE_MANDATORY_STATUS_RESET,

    GET_ACCESSORIES_TELE_SALES_LIST_BEGIN, GET_ACCESSORIES_TELE_SALES_LIST_SUCCESS, GET_ACCESSORIES_TELE_SALES_LIST_ERR,
    GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_BEGIN, GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_SUCCESS, GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_ERR,
    UPDATE_ACCESSORIES_TELE_SALES_STATUS_BEGIN, UPDATE_ACCESSORIES_TELE_SALES_STATUS_SUCCESS, UPDATE_ACCESSORIES_TELE_SALES_STATUS_ERR, UPDATE_ACCESSORIES_TELE_SALES_STATUS_RESET,

    GET_DEMO_HAPPY_SAP_LIST_BEGIN, GET_DEMO_HAPPY_SAP_LIST_SUCCESS, GET_DEMO_HAPPY_SAP_LIST_ERR, 
    UPDATE_DEMO_HAPPY_SAP_STATUS_BEGIN, UPDATE_DEMO_HAPPY_SAP_STATUS_SUCCESS, UPDATE_DEMO_HAPPY_SAP_STATUS_ERR, UPDATE_DEMO_HAPPY_SAP_STATUS_RESET,

    GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_BEGIN, GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_SUCCESS, GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_ERR,
    GET_DEMO_HAPPY_SAP_PRIORITY_LIST_BEGIN, GET_DEMO_HAPPY_SAP_PRIORITY_LIST_SUCCESS, GET_DEMO_HAPPY_SAP_PRIORITY_LIST_ERR,

    GET_AGENT_CALL_POPUP_CUSTOMER_BEGIN,GET_AGENT_CALL_POPUP_CUSTOMER_SUCCESS, GET_AGENT_CALL_POPUP_CUSTOMER_ERR, 
    GET_DOWNLOAD_COMPLAINT_DETAIL_BEGIN,GET_DOWNLOAD_COMPLAINT_DETAIL_SUCCESS,GET_DOWNLOAD_COMPLAINT_DETAIL_ERR,
    GET_REFERENCE_DATA_BEGIN,GET_REFERENCE_DATA_SUCCESS,GET_REFERENCE_DATA_ERR,
    GET_AGENT_BASED_CALL_RECORD_DATA_BEGIN, GET_AGENT_BASED_CALL_RECORD_DATA_SUCCESS,GET_AGENT_BASED_CALL_RECORD_DATA_ERR,

    AGENT_REPORT_DATA_BEGIN, AGENT_REPORT_DATA_SUCCESS, AGENT_REPORT_DATA_ERR,
    AGENT_REPORT_SAVE_DATA_BEGIN, AGENT_REPORT_SAVE_DATA_SUCCESS, AGENT_REPORT_SAVE_DATA_ERR, 
    AGENT_REPORT_DOWNLOAD_DATA_BEGIN, AGENT_REPORT_DOWNLOAD_DATA_SUCCESS, AGENT_REPORT_DOWNLOAD_DATA_ERR, 
    STOP_TIMER_DATA_BEGIN,STOP_TIMER_DATA_SUCCESS,STOP_TIMER_DATA_ERR,STOP_TIMER_DATA_RESET,
 } = actions;

// const initialState = { data: [], loading: false, error: null};

const initialState = { data: '', loading: false, error: null, };
const initialStateArray = { data: [], loading: false, error: null, };

const initialStateAgent = {
    // data: Cookies.get('agentStatus')?JSON.parse(Cookies.get('agentStatus')):'',
    data:'',
    loading: false,
    error: null,
};

const agentCurrentStatusReducers = (state = initialStateAgent, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_STATUS_DATA_BEGIN:
            return {
                ...initialStateAgent,
                loading: true,
            };
        case AGENT_STATUS_DATA_SUCCESS:
            return {
                ...initialStateAgent,
                data,
                error:null,
                loading: false,
            };
        case AGENT_STATUS_DATA_ERR:
            return {
                ...initialStateAgent,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
};

const agentUpdateStatusReducers = (state = initialStateAgent, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_UPDATE_STATUS_DATA_BEGIN:
            return {
                ...initialStateAgent,
                loading: true,
            };
        case AGENT_UPDATE_STATUS_DATA_SUCCESS:
            return {
                ...initialStateAgent,
                data,
                error:null,
                loading: false,
            };
        case AGENT_UPDATE_STATUS_DATA_ERR:
            return {
                ...initialStateAgent,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
};

const agentUpdateCallStatusReducers = (state = initialStateAgent, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_UPDATE_CALL_STATUS_DATA_BEGIN:
            return {
                ...initialStateAgent,
                loading: true,
            };
        case AGENT_UPDATE_CALL_STATUS_DATA_SUCCESS:
            return {
                ...initialStateAgent,
                data,
                error:null,
                loading: false,
            };
        case AGENT_UPDATE_CALL_STATUS_DATA_ERR:
            return {
                ...initialStateAgent,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
};

const agentClicktoCallStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_CLICK_TO_CALL_STATUS_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_CLICK_TO_CALL_STATUS_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case AGENT_CLICK_TO_CALL_STATUS_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
};

const agentManualClicktoCallStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
};

const agentSubmitCallStatusReducers = (state = initialStateAgent, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_CHANGE_CALL_STATUS_DATA_BEGIN:
            return {
                ...initialStateAgent,
                loading: true,
            };
        case AGENT_CHANGE_CALL_STATUS_DATA_SUCCESS:
            return {
                ...initialStateAgent,
                data,
                error:null,
                loading: false,
            };
        case AGENT_CHANGE_CALL_STATUS_DATA_ERR:
            return {
                ...initialStateAgent,
                error: err,
                data:null,
                loading: false,
            };

        case AGENT_CHANGE_CALL_STATUS_DATA_RESET:
            return initialStateAgent;

        default:
            return state;
    }
};




const submitPurchaseEnquiryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_SUBMIT_PURCHASE_DATA_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case AGENT_SUBMIT_PURCHASE_DATA_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case AGENT_SUBMIT_PURCHASE_DATA_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const resubmitPurchaseEnquiryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_RESUBMIT_PURCHASE_DATA_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case AGENT_RESUBMIT_PURCHASE_DATA_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case AGENT_RESUBMIT_PURCHASE_DATA_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const agentListReducers = (state = initialStateArray, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_LIST_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_LIST_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case AGENT_LIST_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const agentWalkoutLeadReducers = (state = initialStateArray, action ) =>{
    const { type, data, err } = action;
    switch (type) {
        case AGENT_WALKOUT_QUERIES_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_WALKOUT_QUERIES_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case AGENT_WALKOUT_QUERIES_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const agentPurchaseLeadReducers = (state = initialStateArray, action ) =>{
    const { type, data, err } = action;
    switch (type) {
        case AGENT_PURCHASE_QUERIES_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_PURCHASE_QUERIES_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case AGENT_PURCHASE_QUERIES_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const agentPurchaseEditLeadReducers = (state = initialStateArray, action ) =>{
    const { type, data, err } = action;
    switch (type) {
        case AGENT_PURCHASE_EDIT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_PURCHASE_EDIT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case AGENT_PURCHASE_EDIT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const agentNumberReducers = (state = initialState, action ) => {
    const { type, data, err } = action;
    
    switch (type) {
        case AGENT_NUMBER_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_NUMBER_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case AGENT_NUMBER_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const dispositionRequestTypeReducers = (state = initialState, action) =>{
    
    const { type, data, err } = action;
    switch (type) {
        case DISPOSITION_REQUEST_TYPE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DISPOSITION_REQUEST_TYPE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                error:null,
                loading: false,
            };
        case DISPOSITION_REQUEST_TYPE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const submitRegisterEnquiryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_SUBMIT_REGISTER_DATA_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case AGENT_SUBMIT_REGISTER_DATA_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case AGENT_SUBMIT_REGISTER_DATA_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getRequestTypesReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_REQUEST_TYPE_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_REQUEST_TYPE_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_REQUEST_TYPE_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const requestSubmittedListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REQUEST_SUBMITTED_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case REQUEST_SUBMITTED_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case REQUEST_SUBMITTED_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const requestSubmittedDetailReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case REQUEST_SUBMITTED_DETAIL_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case REQUEST_SUBMITTED_DETAIL_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case REQUEST_SUBMITTED_DETAIL_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const happydemoComplaintReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case HAPPY_COMPLAINT_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case HAPPY_COMPLAINT_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case HAPPY_COMPLAINT_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const updateComplaintIDReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_COMPLAINT_ID_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case UPDATE_COMPLAINT_ID_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case UPDATE_COMPLAINT_ID_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };
        case UPDATE_COMPLAINT_ID_RESET:
            return initialState;
        default:
            return state;
    }
}

const updatePriorityComplaintIDReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_PRIORITY_COMPLAINT_ID_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case UPDATE_PRIORITY_COMPLAINT_ID_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case UPDATE_PRIORITY_COMPLAINT_ID_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };
        case UPDATE_PRIORITY_COMPLAINT_ID_RESET:
            return initialState;
        default:
            return state;
    }
}

const updatePriorityToScheduledReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_PRIORITY_TO_SCHEDULED_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case UPDATE_PRIORITY_TO_SCHEDULED_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case UPDATE_PRIORITY_TO_SCHEDULED_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };
        case UPDATE_PRIORITY_TO_SCHEDULED_RESET:
            return initialState;
        default:
            return state;
    }
}

const getRegsiterDispositionReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_REGISTER_DISPOSITION_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_REGISTER_DISPOSITION_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_REGISTER_DISPOSITION_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const updateRegisterStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_REGISTER_STATUS_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case UPDATE_REGISTER_STATUS_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case UPDATE_REGISTER_STATUS_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };
        case UPDATE_REGISTER_STATUS_RESET:
            return initialState;

        default:
            return state;
    }
}

const getPriorityListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_PRIORITY_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_PRIORITY_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_PRIORITY_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getScheduleListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_SCHEDULE_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_SCHEDULE_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_SCHEDULE_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getLegalScheduleListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_LEGAL_SCHEDULE_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_LEGAL_SCHEDULE_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_LEGAL_SCHEDULE_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getDemoListReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_DEMO_REGISTER_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_DEMO_REGISTER_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_DEMO_REGISTER_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getAbandonedReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_ABANDONED_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_ABANDONED_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_ABANDONED_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getAbandonedDispositionReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_ABANDONED_DISPOSITION_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_ABANDONED_DISPOSITION_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_ABANDONED_DISPOSITION_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const updateAbandonedStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_ABANDONED_STATUS_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case UPDATE_ABANDONED_STATUS_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case UPDATE_ABANDONED_STATUS_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };
        case UPDATE_ABANDONED_STATUS_RESET:
            return initialState;

        default:
            return state;
    }
}

const getMandatoryReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_MANDATORY_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_MANDATORY_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_MANDATORY_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getMandatoryDispositionReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_MANDATORY_DISPOSITION_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_MANDATORY_DISPOSITION_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_MANDATORY_DISPOSITION_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const updateMandatoryStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_MANDATORY_STATUS_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case UPDATE_MANDATORY_STATUS_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case UPDATE_MANDATORY_STATUS_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };
        case UPDATE_MANDATORY_STATUS_RESET:
            return initialState;
        default:
            return state;
    }
}

const getAccessoriesTeleSalesReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_ACCESSORIES_TELE_SALES_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_ACCESSORIES_TELE_SALES_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_ACCESSORIES_TELE_SALES_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getAccessoriesTeleSalesDispositionReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const updateAccessoriesTeleSalesStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_ACCESSORIES_TELE_SALES_STATUS_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case UPDATE_ACCESSORIES_TELE_SALES_STATUS_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case UPDATE_ACCESSORIES_TELE_SALES_STATUS_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };
        case UPDATE_ACCESSORIES_TELE_SALES_STATUS_RESET:
            return initialState;
        default:
            return state;
    }
}

const getDemoHappySapReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_DEMO_HAPPY_SAP_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_DEMO_HAPPY_SAP_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_DEMO_HAPPY_SAP_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const updateDemoHappySapStatusReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case UPDATE_DEMO_HAPPY_SAP_STATUS_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case UPDATE_DEMO_HAPPY_SAP_STATUS_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case UPDATE_DEMO_HAPPY_SAP_STATUS_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };
        case UPDATE_DEMO_HAPPY_SAP_STATUS_RESET:
            return initialState;
        default:
            return state;
    }
}

const getDemoHappySapScheduledReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getDemoHappySapPrioityReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_DEMO_HAPPY_SAP_PRIORITY_LIST_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_DEMO_HAPPY_SAP_PRIORITY_LIST_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_DEMO_HAPPY_SAP_PRIORITY_LIST_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}


const getAgentCallPopupCustomerReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_AGENT_CALL_POPUP_CUSTOMER_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_AGENT_CALL_POPUP_CUSTOMER_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_AGENT_CALL_POPUP_CUSTOMER_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getDownloadComplaintDetailReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_DOWNLOAD_COMPLAINT_DETAIL_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_DOWNLOAD_COMPLAINT_DETAIL_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_DOWNLOAD_COMPLAINT_DETAIL_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getReferenceDetailReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_REFERENCE_DATA_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_REFERENCE_DATA_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_REFERENCE_DATA_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const getAgentBasedCallRecordReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case GET_AGENT_BASED_CALL_RECORD_DATA_BEGIN:
            return {
                data:null,
                error:null,
                loading: true,
            };
        case GET_AGENT_BASED_CALL_RECORD_DATA_SUCCESS:
            return {
                data,
                error:null,
                loading: false,
            };
        case GET_AGENT_BASED_CALL_RECORD_DATA_ERR:
            return {
                error: err,
                data:null,
                loading: false,
            };

        default:
            return state;
    }
}

const agentReportReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_REPORT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_REPORT_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case AGENT_REPORT_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const agentReportSaveReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_REPORT_SAVE_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_REPORT_SAVE_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case AGENT_REPORT_SAVE_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};

const agentReportDownloadReducers = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case AGENT_REPORT_DOWNLOAD_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case AGENT_REPORT_DOWNLOAD_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case AGENT_REPORT_DOWNLOAD_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        default:
            return state;
    }
};


const stopTimerReducer = (state = initialState, action) =>{
    const { type, data, err } = action;
    switch (type) {
        case STOP_TIMER_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case STOP_TIMER_DATA_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case STOP_TIMER_DATA_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };

        case STOP_TIMER_DATA_RESET:
            return initialState;
    
        default:
            return state;
    }
}

export { 
    agentCurrentStatusReducers, agentUpdateStatusReducers, agentUpdateCallStatusReducers, agentClicktoCallStatusReducers,agentManualClicktoCallStatusReducers,
    agentSubmitCallStatusReducers,
    agentWalkoutLeadReducers,
    agentListReducers, submitPurchaseEnquiryReducers, resubmitPurchaseEnquiryReducers, 
    agentPurchaseLeadReducers, agentPurchaseEditLeadReducers, agentNumberReducers, dispositionRequestTypeReducers, submitRegisterEnquiryReducers, 
    getRequestTypesReducers, requestSubmittedListReducers, requestSubmittedDetailReducers, 
    happydemoComplaintReducers, updateComplaintIDReducers, updatePriorityComplaintIDReducers, updatePriorityToScheduledReducers, getRegsiterDispositionReducers, updateRegisterStatusReducers,
    getPriorityListReducers, getScheduleListReducers,getLegalScheduleListReducers,  
    getAbandonedReducers, getAbandonedDispositionReducers,updateAbandonedStatusReducers,
    getMandatoryReducers, getMandatoryDispositionReducers, updateMandatoryStatusReducers,
    getAccessoriesTeleSalesReducers,getAccessoriesTeleSalesDispositionReducers,updateAccessoriesTeleSalesStatusReducers,
    getDemoHappySapReducers, updateDemoHappySapStatusReducers, getDemoHappySapScheduledReducers,  getDemoHappySapPrioityReducers,
    getAgentCallPopupCustomerReducers, getDownloadComplaintDetailReducers, getReferenceDetailReducers, 
    getAgentBasedCallRecordReducers, getDemoListReducers, 
    agentReportReducers, agentReportSaveReducers, agentReportDownloadReducers,
    stopTimerReducer,
};