import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/review/Dashboard'));
// const FollowLegal               = lazy(() => import('../../container/review/FollowLegal'));
// const ViewFollowDetails         = lazy(() => import('../../container/review/ViewFollowDetails'));
const Report                    = lazy(() => import('../../container/review/Report'));
const DownloadReport            = lazy(() => import('../../container/review/DownloadReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function ReviewRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />
            {/* <Route exact path={`${path}/legal_case_log`} component={FollowLegal} /> */}
            {/* <Route exact path={`${path}/legal_case_log/view`} component={ViewFollowDetails} /> */}
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(ReviewRoutes);