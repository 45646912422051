import React, { lazy } from 'react';
import { Switch, Route,useRouteMatch,Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/customercare/Dashboard'));

const LiveCallsSelf = lazy(() => import('../../container/customercare/LiveCallsSelf'));
const LiveCallsBlrSip = lazy(() => import('../../container/customercare/LiveCallsBLRSIP'));


const AgentSignReport = lazy(() => import('../../container/customercare/AgentSignReport'));
const CallsReport = lazy(() => import('../../container/customercare/CallsReport'));
const CallsBargeReport = lazy(() => import('../../container/customercare/CallsBargeReport'));

const VOIPCallsIncomingReport = lazy(() => import('../../container/customercare/VOIPCallsIncomingReport'));
const HoursActivityReport = lazy(() => import('../../container/customercare/HoursActivityReport'));


const AgentList = lazy(() => import('../../container/customercare/AgentList'))

const RequestList = lazy(() => import('../../container/customercare/RequestList'))
const SubRequestList = lazy(() => import('../../container/customercare/SubRequestList'))
const BreakDetails = lazy(() => import('../../container/customercare/BreakDetails'))

const Report = lazy(() => import('../../container/customercare/Report'));
const DownloadReport    = lazy(() => import('../../container/customercare/DownloadReport'));

function NotFound() {
    return <Redirect to="/admin" />;
}

function CustomercareRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />

            <Route exact path={`${path}/live_calls_self`} component={LiveCallsSelf} />
            <Route exact path={`${path}/live_calls_blr_sip`} component={LiveCallsBlrSip} />

            <Route exact path={`${path}/signin_signout`} component={AgentSignReport} />
            <Route exact path={`${path}/call_report`} component={CallsReport} />
            <Route exact path={`${path}/call_barging_report`} component={CallsBargeReport} />
            <Route exact path={`${path}/voip_incoming_call`} component={VOIPCallsIncomingReport} />
            <Route exact path={`${path}/hours_activity_report`} component={HoursActivityReport} />
            
            

            <Route exact path={`${path}/agent`} component={AgentList} />

            <Route exact path={`${path}/setting_request_type`} component={RequestList} />
            <Route exact path={`${path}/setting_sub_request_type`} component={SubRequestList} />
            <Route exact path={`${path}/setting_break_details`} component={BreakDetails} />
            <Route exact path={`${path}/setting_disposition`} component={BreakDetails} />
             
            <Route exact path={`${path}/report`} component={Report} />
            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(CustomercareRoutes);
