const actions = {

    AGENT_STATUS_DATA_BEGIN : 'AGENT_STATUS_DATA_BEGIN',
    AGENT_STATUS_DATA_SUCCESS: 'AGENT_STATUS_DATA_SUCCESS',
    AGENT_STATUS_DATA_ERR : 'AGENT_STATUS_DATA_ERR',

    AGENT_CHANGE_STATUS_DATA_BEGIN : 'AGENT_CHANGE_STATUS_DATA_BEGIN',
    AGENT_CHANGE_STATUS_DATA_SUCCESS: 'AGENT_CHANGE_STATUS_DATA_SUCCESS',
    AGENT_CHANGE_STATUS_DATA_ERR : 'AGENT_CHANGE_STATUS_DATA_ERR',


    AGENT_CLICK_TO_CALL_STATUS_DATA_BEGIN : 'AGENT_CLICK_TO_CALL_STATUS_DATA_BEGIN',
    AGENT_CLICK_TO_CALL_STATUS_DATA_SUCCESS: 'AGENT_CLICK_TO_CALL_STATUS_DATA_SUCCESS',
    AGENT_CLICK_TO_CALL_STATUS_DATA_ERR : 'AGENT_CLICK_TO_CALL_STATUS_DATA_ERR',

    AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_BEGIN : 'AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_BEGIN',
    AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_SUCCESS: 'AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_SUCCESS',
    AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_ERR : 'AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_ERR',


    AGENT_CHANGE_CALL_STATUS_DATA_BEGIN : 'AGENT_CHANGE_CALL_STATUS_DATA_BEGIN',
    AGENT_CHANGE_CALL_STATUS_DATA_SUCCESS: 'AGENT_CHANGE_CALL_STATUS_DATA_SUCCESS',
    AGENT_CHANGE_CALL_STATUS_DATA_ERR : 'AGENT_CHANGE_CALL_STATUS_DATA_ERR',
    AGENT_CHANGE_CALL_STATUS_DATA_RESET: 'AGENT_CHANGE_CALL_STATUS_DATA_RESET',


    AGENT_SUBMIT_PURCHASE_DATA_BEGIN : 'AGENT_SUBMIT_PURCHASE_DATA_BEGIN',
    AGENT_SUBMIT_PURCHASE_DATA_SUCCESS: 'AGENT_SUBMIT_PURCHASE_DATA_SUCCESS',
    AGENT_SUBMIT_PURCHASE_DATA_ERR : 'AGENT_SUBMIT_PURCHASE_DATA_ERR',

    AGENT_RESUBMIT_PURCHASE_DATA_BEGIN : 'AGENT_RESUBMIT_PURCHASE_DATA_BEGIN',
    AGENT_RESUBMIT_PURCHASE_DATA_SUCCESS: 'AGENT_RESUBMIT_PURCHASE_DATA_SUCCESS',
    AGENT_RESUBMIT_PURCHASE_DATA_ERR : 'AGENT_RESUBMIT_PURCHASE_DATA_ERR',


    AGENT_LIST_DATA_BEGIN : 'AGENT_LIST_DATA_BEGIN',
    AGENT_LIST_DATA_SUCCESS: 'AGENT_LIST_DATA_SUCCESS',
    AGENT_LIST_DATA_ERR : 'AGENT_LIST_DATA_ERR',

    AGENT_WALKOUT_QUERIES_DATA_BEGIN : 'AGENT_WALKOUT_QUERIES_DATA_BEGIN',
    AGENT_WALKOUT_QUERIES_DATA_SUCCESS: 'AGENT_WALKOUT_QUERIES_DATA_SUCCESS',
    AGENT_WALKOUT_QUERIES_DATA_ERR : 'AGENT_WALKOUT_QUERIES_DATA_ERR',

    AGENT_PURCHASE_QUERIES_DATA_BEGIN : 'AGENT_PURCHASE_QUERIES_DATA_BEGIN',
    AGENT_PURCHASE_QUERIES_DATA_SUCCESS: 'AGENT_PURCHASE_QUERIES_DATA_SUCCESS',
    AGENT_PURCHASE_QUERIES_DATA_ERR : 'AGENT_PURCHASE_QUERIES_DATA_ERR',

    AGENT_PURCHASE_EDIT_DATA_BEGIN : 'AGENT_PURCHASE_EDIT_DATA_BEGIN',
    AGENT_PURCHASE_EDIT_DATA_SUCCESS: 'AGENT_PURCHASE_EDIT_DATA_SUCCESS',
    AGENT_PURCHASE_EDIT_DATA_ERR : 'AGENT_PURCHASE_EDIT_DATA_ERR',

    AGENT_NUMBER_DATA_BEGIN : 'AGENT_NUMBER_DATA_BEGIN',
    AGENT_NUMBER_DATA_SUCCESS: 'AGENT_NUMBER_DATA_SUCCESS',
    AGENT_NUMBER_DATA_ERR : 'AGENT_NUMBER_DATA_ERR',


    DISPOSITION_REQUEST_TYPE_DATA_BEGIN : 'DISPOSITION_REQUEST_TYPE_DATA_BEGIN',
    DISPOSITION_REQUEST_TYPE_DATA_SUCCESS : 'DISPOSITION_REQUEST_TYPE_DATA_SUCCESS',
    DISPOSITION_REQUEST_TYPE_DATA_ERR : 'DISPOSITION_REQUEST_TYPE_DATA_ERR',


    AGENT_SUBMIT_REGISTER_DATA_BEGIN: 'AGENT_SUBMIT_REGISTER_DATA_BEGIN',
    AGENT_SUBMIT_REGISTER_DATA_SUCCESS : 'AGENT_SUBMIT_REGISTER_DATA_SUCCESS',
    AGENT_SUBMIT_REGISTER_DATA_ERR : 'AGENT_SUBMIT_REGISTER_DATA_ERR',


    GET_REQUEST_TYPE_BEGIN: 'GET_REQUEST_TYPE_BEGIN',
    GET_REQUEST_TYPE_SUCCESS: 'GET_REQUEST_TYPE_SUCCESS',
    GET_REQUEST_TYPE_ERR: 'GET_REQUEST_TYPE_ERR',

    REQUEST_SUBMITTED_LIST_BEGIN: 'REQUEST_SUBMITTED_LIST_BEGIN',
    REQUEST_SUBMITTED_LIST_SUCCESS: 'REQUEST_SUBMITTED_LIST_SUCCESS',
    REQUEST_SUBMITTED_LIST_ERR: 'REQUEST_SUBMITTED_LIST_ERR',

    REQUEST_SUBMITTED_DETAIL_BEGIN: 'REQUEST_SUBMITTED_DETAIL_BEGIN',
    REQUEST_SUBMITTED_DETAIL_SUCCESS: 'REQUEST_SUBMITTED_DETAIL_SUCCESS',
    REQUEST_SUBMITTED_DETAIL_ERR: 'REQUEST_SUBMITTED_DETAIL_ERR',

    HAPPY_COMPLAINT_LIST_BEGIN : 'HAPPY_COMPLAINT_LIST_BEGIN',
    HAPPY_COMPLAINT_LIST_SUCCESS : 'HAPPY_COMPLAINT_LIST_SUCCESS',
    HAPPY_COMPLAINT_LIST_ERR : 'HAPPY_COMPLAINT_LIST_ERR',

    UPDATE_COMPLAINT_ID_BEGIN: 'UPDATE_COMPLAINT_ID_BEGIN',
    UPDATE_COMPLAINT_ID_SUCCESS: 'UPDATE_COMPLAINT_ID_SUCCESS',
    UPDATE_COMPLAINT_ID_ERR: 'UPDATE_COMPLAINT_ID_ERR',
    UPDATE_COMPLAINT_ID_RESET:'UPDATE_COMPLAINT_ID_RESET',


    UPDATE_PRIORITY_COMPLAINT_ID_BEGIN: 'UPDATE_PRIORITY_COMPLAINT_ID_BEGIN',
    UPDATE_PRIORITY_COMPLAINT_ID_SUCCESS: 'UPDATE_PRIORITY_COMPLAINT_ID_SUCCESS',
    UPDATE_PRIORITY_COMPLAINT_ID_ERR: 'UPDATE_PRIORITY_COMPLAINT_ID_ERR',
    UPDATE_PRIORITY_COMPLAINT_ID_RESET:'UPDATE_PRIORITY_COMPLAINT_ID_RESET',

    UPDATE_PRIORITY_TO_SCHEDULED_BEGIN: 'UPDATE_PRIORITY_TO_SCHEDULED_BEGIN',
    UPDATE_PRIORITY_TO_SCHEDULED_SUCCESS: 'UPDATE_PRIORITY_TO_SCHEDULED_SUCCESS',
    UPDATE_PRIORITY_TO_SCHEDULED_ERR: 'UPDATE_PRIORITY_TO_SCHEDULED_ERR',
    UPDATE_PRIORITY_TO_SCHEDULED_RESET:'UPDATE_PRIORITY_TO_SCHEDULED_RESET',

    GET_REGISTER_DISPOSITION_LIST_BEGIN: 'GET_REGISTER_DISPOSITION_LIST_BEGIN',
    GET_REGISTER_DISPOSITION_LIST_SUCCESS: 'GET_REGISTER_DISPOSITION_LIST_SUCCESS',
    GET_REGISTER_DISPOSITION_LIST_ERR: 'GET_REGISTER_DISPOSITION_LIST_ERR',

    UPDATE_REGISTER_STATUS_BEGIN: 'UPDATE_REGISTER_STATUS_BEGIN',
    UPDATE_REGISTER_STATUS_SUCCESS: 'UPDATE_REGISTER_STATUS_SUCCESS',
    UPDATE_REGISTER_STATUS_ERR: 'UPDATE_REGISTER_STATUS_ERR',
    UPDATE_REGISTER_STATUS_RESET: 'UPDATE_REGISTER_STATUS_RESET',

    GET_PRIORITY_LIST_BEGIN: 'GET_PRIORITY_LIST_BEGIN',
    GET_PRIORITY_LIST_SUCCESS: 'GET_PRIORITY_LIST_SUCCESS',
    GET_PRIORITY_LIST_ERR: 'GET_PRIORITY_LIST_ERR',
    
    GET_SCHEDULE_LIST_BEGIN: 'GET_SCHEDULE_LIST_BEGIN',
    GET_SCHEDULE_LIST_SUCCESS: 'GET_SCHEDULE_LIST_SUCCESS',
    GET_SCHEDULE_LIST_ERR : 'GET_SCHEDULE_LIST_ERR',


    GET_LEGAL_SCHEDULE_LIST_BEGIN: 'GET_LEGAL_SCHEDULE_LIST_BEGIN',
    GET_LEGAL_SCHEDULE_LIST_SUCCESS: 'GET_LEGAL_SCHEDULE_LIST_SUCCESS',
    GET_LEGAL_SCHEDULE_LIST_ERR : 'GET_LEGAL_SCHEDULE_LIST_ERR',

    

    GET_DEMO_REGISTER_LIST_BEGIN: 'GET_DEMO_REGISTER_LIST_BEGIN',
    GET_DEMO_REGISTER_LIST_SUCCESS: 'GET_DEMO_REGISTER_LIST_SUCCESS',
    GET_DEMO_REGISTER_LIST_ERR : 'GET_DEMO_REGISTER_LIST_ERR',

    GET_ABANDONED_LIST_BEGIN: 'GET_ABANDONED_LIST_BEGIN',
    GET_ABANDONED_LIST_SUCCESS: 'GET_ABANDONED_LIST_SUCCESS',
    GET_ABANDONED_LIST_ERR : 'GET_ABANDONED_LIST_ERR',

    GET_ABANDONED_DISPOSITION_LIST_BEGIN : 'GET_ABANDONED_DISPOSITION_LIST_BEGIN',
    GET_ABANDONED_DISPOSITION_LIST_SUCCESS : 'GET_ABANDONED_DISPOSITION_LIST_SUCCESS',
    GET_ABANDONED_DISPOSITION_LIST_ERR : 'GET_ABANDONED_DISPOSITION_LIST_ERR',

    UPDATE_ABANDONED_STATUS_BEGIN: 'UPDATE_ABANDONED_STATUS_BEGIN',
    UPDATE_ABANDONED_STATUS_SUCCESS: 'UPDATE_ABANDONED_STATUS_SUCCESS',
    UPDATE_ABANDONED_STATUS_ERR: 'UPDATE_ABANDONED_STATUS_ERR',
    UPDATE_ABANDONED_STATUS_RESET: 'UPDATE_ABANDONED_STATUS_RESET',

    GET_MANDATORY_LIST_BEGIN: 'GET_MANDATORY_LIST_BEGIN',
    GET_MANDATORY_LIST_SUCCESS: 'GET_MANDATORY_LIST_SUCCESS',
    GET_MANDATORY_LIST_ERR : 'GET_MANDATORY_LIST_ERR',

    GET_MANDATORY_DISPOSITION_LIST_BEGIN : 'GET_MANDATORY_DISPOSITION_LIST_BEGIN',
    GET_MANDATORY_DISPOSITION_LIST_SUCCESS : 'GET_MANDATORY_DISPOSITION_LIST_SUCCESS',
    GET_MANDATORY_DISPOSITION_LIST_ERR : 'GET_MANDATORY_DISPOSITION_LIST_ERR',

    UPDATE_MANDATORY_STATUS_BEGIN: 'UPDATE_MANDATORY_STATUS_BEGIN',
    UPDATE_MANDATORY_STATUS_SUCCESS: 'UPDATE_MANDATORY_STATUS_SUCCESS',
    UPDATE_MANDATORY_STATUS_ERR: 'UPDATE_MANDATORY_STATUS_ERR',
    UPDATE_MANDATORY_STATUS_RESET: 'UPDATE_MANDATORY_STATUS_RESET',


    GET_ACCESSORIES_TELE_SALES_LIST_BEGIN: 'GET_ACCESSORIES_TELE_SALES_LIST_BEGIN',
    GET_ACCESSORIES_TELE_SALES_LIST_SUCCESS: 'GET_ACCESSORIES_TELE_SALES_LIST_SUCCESS',
    GET_ACCESSORIES_TELE_SALES_LIST_ERR : 'GET_ACCESSORIES_TELE_SALES_LIST_ERR',

    GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_BEGIN : 'GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_BEGIN',
    GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_SUCCESS : 'GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_SUCCESS',
    GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_ERR : 'GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_ERR',

    UPDATE_ACCESSORIES_TELE_SALES_STATUS_BEGIN: 'UPDATE_ACCESSORIES_TELE_SALES_STATUS_BEGIN',
    UPDATE_ACCESSORIES_TELE_SALES_STATUS_SUCCESS: 'UPDATE_ACCESSORIES_TELE_SALES_STATUS_SUCCESS',
    UPDATE_ACCESSORIES_TELE_SALES_STATUS_ERR: 'UPDATE_ACCESSORIES_TELE_SALES_STATUS_ERR',
    UPDATE_ACCESSORIES_TELE_SALES_STATUS_RESET: 'UPDATE_ACCESSORIES_TELE_SALES_STATUS_RESET',

    GET_DEMO_HAPPY_SAP_LIST_BEGIN : 'GET_DEMO_HAPPY_SAP_LIST_BEGIN',
    GET_DEMO_HAPPY_SAP_LIST_SUCCESS: 'GET_DEMO_HAPPY_SAP_LIST_SUCCESS',
    GET_DEMO_HAPPY_SAP_LIST_ERR : 'GET_DEMO_HAPPY_SAP_LIST_ERR',

    UPDATE_DEMO_HAPPY_SAP_STATUS_BEGIN : 'UPDATE_DEMO_HAPPY_SAP_STATUS_BEGIN',
    UPDATE_DEMO_HAPPY_SAP_STATUS_SUCCESS : 'UPDATE_DEMO_HAPPY_SAP_STATUS_SUCCESS',
    UPDATE_DEMO_HAPPY_SAP_STATUS_ERR: 'UPDATE_DEMO_HAPPY_SAP_STATUS_ERR',
    UPDATE_DEMO_HAPPY_SAP_STATUS_RESET : 'UPDATE_DEMO_HAPPY_SAP_STATUS_RESET',

    GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_BEGIN : 'GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_BEGIN',
    GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_SUCCESS: 'GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_SUCCESS',
    GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_ERR : 'GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_ERR',

    GET_DEMO_HAPPY_SAP_PRIORITY_LIST_BEGIN : 'GET_DEMO_HAPPY_SAP_PRIORITY_LIST_BEGIN',
    GET_DEMO_HAPPY_SAP_PRIORITY_LIST_SUCCESS: 'GET_DEMO_HAPPY_SAP_PRIORITY_LIST_SUCCESS',
    GET_DEMO_HAPPY_SAP_PRIORITY_LIST_ERR : 'GET_DEMO_HAPPY_SAP_PRIORITY_LIST_ERR',

    GET_AGENT_CALL_POPUP_CUSTOMER_BEGIN : 'GET_AGENT_CALL_POPUP_CUSTOMER_BEGIN',
    GET_AGENT_CALL_POPUP_CUSTOMER_SUCCESS : 'GET_AGENT_CALL_POPUP_CUSTOMER_SUCCESS',
    GET_AGENT_CALL_POPUP_CUSTOMER_ERR : 'GET_AGENT_CALL_POPUP_CUSTOMER_ERR',

    GET_DOWNLOAD_COMPLAINT_DETAIL_BEGIN : 'GET_DOWNLOAD_COMPLAINT_DETAIL_BEGIN',
    GET_DOWNLOAD_COMPLAINT_DETAIL_SUCCESS : 'GET_DOWNLOAD_COMPLAINT_DETAIL_SUCCESS',
    GET_DOWNLOAD_COMPLAINT_DETAIL_ERR : 'GET_DOWNLOAD_COMPLAINT_DETAIL_ERR',

    GET_REFERENCE_DATA_BEGIN : 'GET_REFERENCE_DATA_BEGIN',
    GET_REFERENCE_DATA_SUCCESS : 'GET_REFERENCE_DATA_SUCCESS',
    GET_REFERENCE_DATA_ERR : 'GET_REFERENCE_DATA_ERR',

    GET_AGENT_BASED_CALL_RECORD_DATA_BEGIN : 'GET_AGENT_BASED_CALL_RECORD_DATA_BEGIN',
    GET_AGENT_BASED_CALL_RECORD_DATA_SUCCESS : 'GET_AGENT_BASED_CALL_RECORD_DATA_SUCCESS',
    GET_AGENT_BASED_CALL_RECORD_DATA_ERR : 'GET_AGENT_BASED_CALL_RECORD_DATA_ERR',

    AGENT_REPORT_DATA_BEGIN : 'AGENT_REPORT_DATA_BEGIN',
    AGENT_REPORT_DATA_SUCCESS: 'AGENT_REPORT_DATA_SUCCESS',
    AGENT_REPORT_DATA_ERR : 'AGENT_REPORT_DATA_ERR',

    AGENT_REPORT_SAVE_DATA_BEGIN : 'AGENT_REPORT_SAVE_DATA_BEGIN',
    AGENT_REPORT_SAVE_DATA_SUCCESS: 'AGENT_REPORT_SAVE_DATA_SUCCESS',
    AGENT_REPORT_SAVE_DATA_ERR : 'AGENT_REPORT_SAVE_DATA_ERR',

    AGENT_REPORT_DOWNLOAD_DATA_BEGIN : 'AGENT_REPORT_DOWNLOAD_DATA_BEGIN',
    AGENT_REPORT_DOWNLOAD_DATA_SUCCESS: 'AGENT_REPORT_DOWNLOAD_DATA_SUCCESS',
    AGENT_REPORT_DOWNLOAD_DATA_ERR : 'AGENT_REPORT_DOWNLOAD_DATA_ERR',

    STOP_TIMER_DATA_BEGIN: 'STOP_TIMER_DATA_BEGIN',
    STOP_TIMER_DATA_SUCCESS : 'STOP_TIMER_DATA_SUCCESS',
    STOP_TIMER_DATA_ERR : 'STOP_TIMER_DATA_ERR',
    STOP_TIMER_DATA_RESET: 'STOP_TIMER_DATA_RESET',

    agentStatusDataBegin: () => {
        return {
            type: actions.AGENT_STATUS_DATA_BEGIN,
        };
    },

    agentStatusDataSuccess: (data) => {
        return {
            type: actions.AGENT_STATUS_DATA_SUCCESS,
            data,
        };
    },

    agentStatusDataErr: (err) => {
        return {
            type: actions.AGENT_STATUS_DATA_ERR,
            err,
        };
    },


    agentChangeStatusDataBegin: () => {
        return {
            type: actions.AGENT_CHANGE_STATUS_DATA_BEGIN,
        };
    },

    agentChangeStatusDataSuccess: (data) => {
        return {
            type: actions.AGENT_CHANGE_STATUS_DATA_SUCCESS,
            data,
        };
    },

    agentChangeStatusDataErr: (err) => {
        return {
            type: actions.AGENT_CHANGE_STATUS_DATA_ERR,
            err,
        };
    },

    agentChangeCallStatusDataBegin: () => {
        return {
            type: actions.AGENT_CHANGE_CALL_STATUS_DATA_BEGIN,
        };
    },
    
    agentChangeCallStatusDataSuccess: (data) => {
        return {
            type: actions.AGENT_CHANGE_CALL_STATUS_DATA_SUCCESS,
            data,
        };
    }, 
    agentChangeCallStatusDataErr: (err) => {
        return {
            type: actions.AGENT_CHANGE_CALL_STATUS_DATA_ERR,
            err,
        };
    },

    agentChangeCallStatusDataReset: () => {
        return {
            type: actions.AGENT_CHANGE_CALL_STATUS_DATA_RESET,
        };
    },
    
    

    agentClicktoCallStatusDataBegin: () => {
        return {
            type: actions.AGENT_CLICK_TO_CALL_STATUS_DATA_BEGIN,
        };
    },
    
    agentClicktoCallStatusDataSuccess: (data) => {
        return {
            type: actions.AGENT_CLICK_TO_CALL_STATUS_DATA_SUCCESS,
            data,
        };
    }, 
    agentClicktoCallStatusDataErr: (err) => {
        return {
            type: actions.AGENT_CLICK_TO_CALL_STATUS_DATA_ERR,
            err,
        };
    },


    agentManualClicktoCallStatusDataBegin: () => {
        return {
            type: actions.AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_BEGIN,
        };
    },
    
    agentManualClicktoCallStatusDataSuccess: (data) => {
        return {
            type: actions.AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_SUCCESS,
            data,
        };
    }, 
    agentManualClicktoCallStatusDataErr: (err) => {
        return {
            type: actions.AGENT_MANUAL_CLICK_TO_CALL_STATUS_DATA_ERR,
            err,
        };
    },

    agentSubmitPurchaseBegin: () => {
        return {
            type: actions.AGENT_SUBMIT_PURCHASE_DATA_BEGIN,
        };
    },

    agentSubmitPurchaseSuccess: (data) => {
        return {
            type: actions.AGENT_SUBMIT_PURCHASE_DATA_SUCCESS,
            data,
        };
    },

    agentSubmitPurchaseErr: (err) => {
        return {
            type: actions.AGENT_SUBMIT_PURCHASE_DATA_ERR,
            err,
        };
    },

    agentResubmitPurchaseBegin: () => {
        return {
            type: actions.AGENT_RESUBMIT_PURCHASE_DATA_BEGIN,
        };
    },

    agentResubmitPurchaseSuccess: (data) => {
        return {
            type: actions.AGENT_RESUBMIT_PURCHASE_DATA_SUCCESS,
            data,
        };
    },

    agentResubmitPurchaseErr: (err) => {
        return {
            type: actions.AGENT_RESUBMIT_PURCHASE_DATA_ERR,
            err,
        };
    },

    agentListBegin: () => {
        return {
            type: actions.AGENT_LIST_DATA_BEGIN,
        };
    },

    agentListSuccess: (data) => {
        return {
            type: actions.AGENT_LIST_DATA_SUCCESS,
            data,
        };
    },

    agentListErr: (err) => {
        return {
            type: actions.AGENT_LIST_DATA_ERR,
            err,
        };
    },


    agentWalkoutQueriesDataBegin: () => {
        return {
            type: actions.AGENT_WALKOUT_QUERIES_DATA_BEGIN,
        };
    },

    agentWalkoutQueriesDataSuccess: (data) => {
        return {
            type: actions.AGENT_WALKOUT_QUERIES_DATA_SUCCESS,
            data,
        };
    },

    agentWalkoutQueriesDataErr: (err) => {
        return {
            type: actions.AGENT_WALKOUT_QUERIES_DATA_ERR,
            err,
        };
    },

    agentPurchaseQueriesDataBegin: () => {
        return {
            type: actions.AGENT_PURCHASE_QUERIES_DATA_BEGIN,
        };
    },

    agentPurchaseQueriesDataSuccess: (data) => {
        return {
            type: actions.AGENT_PURCHASE_QUERIES_DATA_SUCCESS,
            data,
        };
    },

    agentPurchaseQueriesDataErr: (err) => {
        return {
            type: actions.AGENT_PURCHASE_QUERIES_DATA_ERR,
            err,
        };
    },

    agentPurchaseEditDataBegin: () => {
        return {
            type: actions.AGENT_PURCHASE_EDIT_DATA_BEGIN,
        };
    },

    agentPurchaseEditDataSuccess: (data) => {
        return {
            type: actions.AGENT_PURCHASE_EDIT_DATA_SUCCESS,
            data,
        };
    },

    agentPurchaseEditDataErr: (err) => {
        return {
            type: actions.AGENT_PURCHASE_EDIT_DATA_ERR,
            err,
        };
    },

    agentNumberBegin: () => {
        return {
            type: actions.AGENT_NUMBER_DATA_BEGIN,
        };
    },

    agentNumberSuccess: (data) => {
        return {
            type: actions.AGENT_NUMBER_DATA_SUCCESS,
            data,
        };
    },

    agentNumberErr: (err) => {
        return {
            type: actions.AGENT_NUMBER_DATA_ERR,
            err,
        };
    },

    getDispositionRequestBegin: () => {
        return {
            type: actions.DISPOSITION_REQUEST_TYPE_DATA_BEGIN,
        };
    },

    getDispositionRequestSuccess: (data) => {
        return {
            type: actions.DISPOSITION_REQUEST_TYPE_DATA_SUCCESS,
            data,
        };
    },

    getDispositionRequestErr: (err) => {
        return {
            type: actions.DISPOSITION_REQUEST_TYPE_DATA_ERR,
            err,
        };
    },
   
    agentSubmitRegisterBegin: () => {
        return {
            type: actions.AGENT_SUBMIT_REGISTER_DATA_BEGIN,
        };
    },

    agentSubmitRegisterSuccess: (data) => {
        return {
            type: actions.AGENT_SUBMIT_REGISTER_DATA_SUCCESS,
            data,
        };
    },

    agentSubmitRegisterErr: (err) => {
        return {
            type: actions.AGENT_SUBMIT_REGISTER_DATA_ERR,
            err,
        };
    },

    
    getRequestTypeBegin: () => {
        return {
            type: actions.GET_REQUEST_TYPE_BEGIN,
        };
    },

    getRequestTypeSuccess: (data) => {
        return {
            type: actions.GET_REQUEST_TYPE_SUCCESS,
            data,
        };
    },

    getRequestTypeErr: (err) => {
        return {
            type: actions.GET_REQUEST_TYPE_ERR,
            err,
        };
    },

    requestSubmittedListBegin: () => {
        return {
            type: actions.REQUEST_SUBMITTED_LIST_BEGIN,
        };
    },

    requestSubmittedListSuccess: (data) => {
        return {
            type: actions.REQUEST_SUBMITTED_LIST_SUCCESS,
            data,
        };
    },

    requestSubmittedListErr: (err) => {
        return {
            type: actions.REQUEST_SUBMITTED_LIST_ERR,
            err,
        };
    },


    requestSubmittedDetailBegin: () => {
        return {
            type: actions.REQUEST_SUBMITTED_DETAIL_BEGIN,
        };
    },

    requestSubmittedDetailSuccess: (data) => {
        return {
            type: actions.REQUEST_SUBMITTED_DETAIL_SUCCESS,
            data,
        };
    },

    requestSubmittedDetailErr: (err) => {
        return {
            type: actions.REQUEST_SUBMITTED_DETAIL_ERR,
            err,
        };
    },

    happyComplaintListBegin: () => {
        return {
            type: actions.HAPPY_COMPLAINT_LIST_BEGIN,
        };
    },

    happyComplaintListSuccess: (data) => {
        return {
            type: actions.HAPPY_COMPLAINT_LIST_SUCCESS,
            data,
        };
    },

    happyComplaintListErr: (err) => {
        return {
            type: actions.HAPPY_COMPLAINT_LIST_ERR,
            err,
        };
    },

   
    updateComplaintIDBegin: () => {
        return {
            type: actions.UPDATE_COMPLAINT_ID_BEGIN,
        };
    },

    updateComplaintIDSuccess: (data) => {
        return {
            type: actions.UPDATE_COMPLAINT_ID_SUCCESS,
            data,
        };
    },

    updateComplaintIDErr: (err) => {
        return {
            type: actions.UPDATE_COMPLAINT_ID_ERR,
            err,
        };
    },

    updateComplaintIDReset: (err) => {
        return {
            type: actions.UPDATE_COMPLAINT_ID_RESET,
            err,
        };
    },
    
    updatePriorityComplaintIDBegin: () => {
        return {
            type: actions.UPDATE_PRIORITY_COMPLAINT_ID_BEGIN,
        };
    },

    updatePriorityComplaintIDSuccess: (data) => {
        return {
            type: actions.UPDATE_PRIORITY_COMPLAINT_ID_SUCCESS,
            data,
        };
    },

    updatePriorityComplaintIDErr: (err) => {
        return {
            type: actions.UPDATE_PRIORITY_COMPLAINT_ID_ERR,
            err,
        };
    },

    updatePriorityComplaintIDReset: (err) => {
        return {
            type: actions.UPDATE_PRIORITY_COMPLAINT_ID_RESET,
            err,
        };
    },

    updatePriorityToScheduledBegin: () => {
        return {
            type: actions.UPDATE_PRIORITY_TO_SCHEDULED_BEGIN,
        };
    },

    updatePriorityToScheduledSuccess: (data) => {
        return {
            type: actions.UPDATE_PRIORITY_TO_SCHEDULED_SUCCESS,
            data,
        };
    },

    updatePriorityToScheduledErr: (err) => {
        return {
            type: actions.UPDATE_PRIORITY_TO_SCHEDULED_ERR,
            err,
        };
    },

    updatePriorityToScheduledReset: (err) => {
        return {
            type: actions.UPDATE_PRIORITY_TO_SCHEDULED_RESET,
            err,
        };
    },

    
    getRegisterDispositionBegin: () => {
        return {
            type: actions.GET_REGISTER_DISPOSITION_LIST_BEGIN,
        };
    },

    getRegisterDispositionSuccess: (data) => {
        return {
            type: actions.GET_REGISTER_DISPOSITION_LIST_SUCCESS,
            data,
        };
    },

    getRegisterDispositionErr: (err) => {
        return {
            type: actions.GET_REGISTER_DISPOSITION_LIST_ERR,
            err,
        };
    },

    updateRegisterStatusBegin: () => {
        return {
            type: actions.UPDATE_REGISTER_STATUS_BEGIN,
        };
    },

    updateRegisterStatusSuccess: (data) => {
        return {
            type: actions.UPDATE_REGISTER_STATUS_SUCCESS,
            data,
        };
    },

    updateRegisterStatusErr: (err) => {
        return {
            type: actions.UPDATE_REGISTER_STATUS_ERR,
            err,
        };
    },

    updateRegisterStatusReset: (err) => {
        return {
            type: actions.UPDATE_REGISTER_STATUS_RESET,
            err,
        };
    },

    getPriorityListBegin: () => {
        return {
            type: actions.GET_PRIORITY_LIST_BEGIN,
        };
    },

    getPriorityListSuccess: (data) => {
        return {
            type: actions.GET_PRIORITY_LIST_SUCCESS,
            data,
        };
    },

    getPriorityListErr: (err) => {
        return {
            type: actions.GET_PRIORITY_LIST_ERR,
            err,
        };
    },

    getScheduleListBegin: () => {
        return {
            type: actions.GET_SCHEDULE_LIST_BEGIN,
        };
    },

    getScheduleListSuccess: (data) => {
        return {
            type: actions.GET_SCHEDULE_LIST_SUCCESS,
            data,
        };
    },

    getScheduleListErr: (err) => {
        return {
            type: actions.GET_SCHEDULE_LIST_ERR,
            err,
        };
    },

    getLegalScheduleListBegin: () => {
        return {
            type: actions.GET_LEGAL_SCHEDULE_LIST_BEGIN,
        };
    },

    getLegalScheduleListSuccess: (data) => {
        return {
            type: actions.GET_LEGAL_SCHEDULE_LIST_SUCCESS,
            data,
        };
    },

    getLegalScheduleListErr: (err) => {
        return {
            type: actions.GET_LEGAL_SCHEDULE_LIST_ERR,
            err,
        };
    },

    getDemoRegisterListBegin: () => {
        return {
            type: actions.GET_DEMO_REGISTER_LIST_BEGIN,
        };
    },

    getDemoRegisterListSuccess: (data) => {
        return {
            type: actions.GET_DEMO_REGISTER_LIST_SUCCESS,
            data,
        };
    },

    getDemoRegisterListErr: (err) => {
        return {
            type: actions.GET_DEMO_REGISTER_LIST_ERR,
            err,
        };
    },

    abandonedCartListBegin: () => {
        return {
            type: actions.GET_ABANDONED_LIST_BEGIN,
        };
    },

    abandonedCartListSuccess: (data) => {
        return {
            type: actions.GET_ABANDONED_LIST_SUCCESS,
            data,
        };
    },

    abandonedCartListErr: (err) => {
        return {
            type: actions.GET_ABANDONED_LIST_ERR,
            err,
        };
    },
    
    getAbandonedDispositionBegin: () => {
        return {
            type: actions.GET_ABANDONED_DISPOSITION_LIST_BEGIN,
        };
    },

    getAbandonedDispositionSuccess: (data) => {
        return {
            type: actions.GET_ABANDONED_DISPOSITION_LIST_SUCCESS,
            data,
        };
    },

    getAbandonedDispositionErr: (err) => {
        return {
            type: actions.GET_ABANDONED_DISPOSITION_LIST_ERR,
            err,
        };
    },


    updateAbandonedBegin: () => {
        return {
            type: actions.UPDATE_ABANDONED_STATUS_BEGIN,
        };
    },

    updateAbandonedSuccess: (data) => {
        return {
            type: actions.UPDATE_ABANDONED_STATUS_SUCCESS,
            data,
        };
    },

    updateAbandonedErr: (err) => {
        return {
            type: actions.UPDATE_ABANDONED_STATUS_ERR,
            err,
        };
    },

    updateAbandonedReset: (err) => {
        return {
            type: actions.UPDATE_ABANDONED_STATUS_RESET,
            err,
        };
    },


    mandatoryServiceListBegin: () => {
        return {
            type: actions.GET_MANDATORY_LIST_BEGIN,
        };
    },
    
    mandatoryServiceListSuccess: (data) => {
        return {
            type: actions.GET_MANDATORY_LIST_SUCCESS,
            data,
        };
    },

    mandatoryServiceListErr: (err) => {
        return {
            type: actions.GET_MANDATORY_LIST_ERR,
            err,
        };
    },

    getMandatoryDispositionBegin: () => {
        return {
            type: actions.GET_MANDATORY_DISPOSITION_LIST_BEGIN,
        };
    },

    getMandatoryDispositionSuccess: (data) => {
        return {
            type: actions.GET_MANDATORY_DISPOSITION_LIST_SUCCESS,
            data,
        };
    },

    getMandatoryDispositionErr: (err) => {
        return {
            type: actions.GET_MANDATORY_DISPOSITION_LIST_ERR,
            err,
        };
    },

    updateMandatoryBegin: () => {
        return {
            type: actions.UPDATE_MANDATORY_STATUS_BEGIN,
        };
    },

    updateMandatorySuccess: (data) => {
        return {
            type: actions.UPDATE_MANDATORY_STATUS_SUCCESS,
            data,
        };
    },

    updateMandatoryErr: (err) => {
        return {
            type: actions.UPDATE_MANDATORY_STATUS_ERR,
            err,
        };
    },

    updateMandatoryReset: (err) => {
        return {
            type: actions.UPDATE_MANDATORY_STATUS_RESET,
            err,
        };
    },

    
    accessoriesTeleSalesListBegin: () => {
        return {
            type: actions.GET_ACCESSORIES_TELE_SALES_LIST_BEGIN,
        };
    },
    
    accessoriesTeleSalesListSuccess: (data) => {
        return {
            type: actions.GET_ACCESSORIES_TELE_SALES_LIST_SUCCESS,
            data,
        };
    },

    accessoriesTeleSalesListErr: (err) => {
        return {
            type: actions.GET_ACCESSORIES_TELE_SALES_LIST_ERR,
            err,
        };
    },

    getAccessoriesTeleSalesDispositionBegin: () => {
        return {
            type: actions.GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_BEGIN,
        };
    },

    getAccessoriesTeleSalesDispositionSuccess: (data) => {
        return {
            type: actions.GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_SUCCESS,
            data,
        };
    },

    getAccessoriesTeleSalesDispositionErr: (err) => {
        return {
            type: actions.GET_ACCESSORIES_TELE_SALES_DISPOSITION_LIST_ERR,
            err,
        };
    },

    updateAccessoriesTeleSalesBegin: () => {
        return {
            type: actions.UPDATE_ACCESSORIES_TELE_SALES_STATUS_BEGIN,
        };
    },

    updateAccessoriesTeleSalesSuccess: (data) => {
        return {
            type: actions.UPDATE_ACCESSORIES_TELE_SALES_STATUS_SUCCESS,
            data,
        };
    },

    updateAccessoriesTeleSalesErr: (err) => {
        return {
            type: actions.UPDATE_ACCESSORIES_TELE_SALES_STATUS_ERR,
            err,
        };
    },

    updateAccessoriesTeleSalesReset: (err) => {
        return {
            type: actions.UPDATE_ACCESSORIES_TELE_SALES_STATUS_RESET,
            err,
        };
    },

    demoHappySapAgentListBegin: () => {
        return {
            type: actions.GET_DEMO_HAPPY_SAP_LIST_BEGIN,
        };
    },
    
    demoHappySapAgentListSuccess: (data) => {
        return {
            type: actions.GET_DEMO_HAPPY_SAP_LIST_SUCCESS,
            data,
        };
    },

    demoHappySapAgentListErr: (err) => {
        return {
            type: actions.GET_DEMO_HAPPY_SAP_LIST_ERR,
            err,
        };
    },

    updateDemoHappySapStatusBegin: () => {
        return {
            type: actions.UPDATE_DEMO_HAPPY_SAP_STATUS_BEGIN,
        };
    },

    updateDemoHappySapStatusSuccess: (data) => {
        return {
            type: actions.UPDATE_DEMO_HAPPY_SAP_STATUS_SUCCESS,
            data,
        };
    },

    updateDemoHappySapStatusErr: (err) => {
        return {
            type: actions.UPDATE_DEMO_HAPPY_SAP_STATUS_ERR,
            err,
        };
    },

    updateDemoHappySapStatusReset: (err) => {
        return {
            type: actions.UPDATE_DEMO_HAPPY_SAP_STATUS_RESET,
            err,
        };
    },
    
    demoHappySapAgentScheduledListBegin: () => {
        return {
            type: actions.GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_BEGIN,
        };
    },
    
    demoHappySapAgentScheduledListSuccess: (data) => {
        return {
            type: actions.GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_SUCCESS,
            data,
        };
    },

    demoHappySapAgentScheduledListErr: (err) => {
        return {
            type: actions.GET_DEMO_HAPPY_SAP_SCHEDULED_LIST_ERR,
            err,
        };
    },

    demoHappySapAgentPriorityListBegin: () => {
        return {
            type: actions.GET_DEMO_HAPPY_SAP_PRIORITY_LIST_BEGIN,
        };
    },
    
    demoHappySapAgentPriorityListSuccess: (data) => {
        return {
            type: actions.GET_DEMO_HAPPY_SAP_PRIORITY_LIST_SUCCESS,
            data,
        };
    },

    demoHappySapAgentPriorityListErr: (err) => {
        return {
            type: actions.GET_DEMO_HAPPY_SAP_PRIORITY_LIST_ERR,
            err,
        };
    },
    
    getAgentCallPopupCustomerBegin: () => {
        return {
            type: actions.GET_AGENT_CALL_POPUP_CUSTOMER_BEGIN,
        };
    },

    getAgentCallPopupCustomerSuccess: (data) => {
        return {
            type: actions.GET_AGENT_CALL_POPUP_CUSTOMER_SUCCESS,
            data,
        };
    },

    getAgentCallPopupCustomerErr: (err) => {
        return {
            type: actions.GET_AGENT_CALL_POPUP_CUSTOMER_ERR,
            err,
        };
    },

    getDownloadComplaintDetailsBegin: () => {
        return {
            type: actions.GET_DOWNLOAD_COMPLAINT_DETAIL_BEGIN,
        };
    },

    getDownloadComplaintDetailsSuccess: (data) => {
        return {
            type: actions.GET_DOWNLOAD_COMPLAINT_DETAIL_SUCCESS,
            data,
        };
    },

    getDownloadComplaintDetailsErr: (err) => {
        return {
            type: actions.GET_DOWNLOAD_COMPLAINT_DETAIL_ERR,
            err,
        };
    },

    getReferenceDataBegin: () => {
        return {
            type: actions.GET_REFERENCE_DATA_BEGIN,
        };
    },

    getReferenceDataSuccess: (data) => {
        return {
            type: actions.GET_REFERENCE_DATA_SUCCESS,
            data,
        };
    },

    getReferenceDataErr: (err) => {
        return {
            type: actions.GET_REFERENCE_DATA_ERR,
            err,
        };
    },

    getAgentBasedCallRecordDataBegin: () => {
        return {
            type: actions.GET_AGENT_BASED_CALL_RECORD_DATA_BEGIN,
        };
    },

    getAgentBasedCallRecordDataSuccess: (data) => {
        return {
            type: actions.GET_AGENT_BASED_CALL_RECORD_DATA_SUCCESS,
            data,
        };
    },

    getAgentBasedCallRecordDataErr: (err) => {
        return {
            type: actions.GET_AGENT_BASED_CALL_RECORD_DATA_ERR,
            err,
        };
    },

    agentReportDataBegin: () => {
        return {
            type: actions.AGENT_REPORT_DATA_BEGIN,
        };
    },

    agentReportDataSuccess: (data) => {
        return {
            type: actions.AGENT_REPORT_DATA_SUCCESS,
            data,
        };
    },

    agentReportDataErr: (err) => {
        return {
            type: actions.AGENT_REPORT_DATA_ERR,
            err,
        };
    },

    agentReportSaveDataBegin: () => {
        return {
            type: actions.AGENT_REPORT_SAVE_DATA_BEGIN,
        };
    },

    agentReportSaveDataSuccess: (data) => {
        return {
            type: actions.AGENT_REPORT_SAVE_DATA_SUCCESS,
            data,
        };
    },

    agentReportSaveDataErr: (err) => {
        return {
            type: actions.AGENT_REPORT_SAVE_DATA_ERR,
            err,
        };
    },

    agentReportDownloadDataBegin: () => {
        return {
            type: actions.AGENT_REPORT_DOWNLOAD_DATA_BEGIN,
        };
    },

    agentReportDownloadDataSuccess: (data) => {
        return {
            type: actions.AGENT_REPORT_DOWNLOAD_DATA_SUCCESS,
            data,
        };
    },

    agentReportDownloadDataErr: (err) => {
        return {
            type: actions.AGENT_REPORT_DOWNLOAD_DATA_ERR,
            err,
        };
    },

    
    stopTimerDataBegin: () => {
        return {
            type: actions.STOP_TIMER_DATA_BEGIN,
        };
    },

    stopTimerDataSuccess: (data) => {
        return {
            type: actions.STOP_TIMER_DATA_SUCCESS,
            data,
        };
    },

    stopTimerDataErr: (err) => {
        return {
            type: actions.STOP_TIMER_DATA_ERR,
            err,
        };
    },

    stopTimerDataReset: (err) => {
        return {
            type: actions.STOP_TIMER_DATA_RESET,
        };
    },
    
    

};

export default actions;
