import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
function CallSwitchBox({agentStatus}) {
    
    const { path } = useRouteMatch();
    
    return (
        
        <div className="call">
            <Link to={`${path}/change_call`} className="head-example" title="Call Change">
                {/* { agentStatus && ( <FeatherIcon icon="phone-call" size={22} /> )} */}
                { agentStatus  && agentStatus?.type === 'in-bound' && ( <FeatherIcon icon="phone-incoming" size={22} /> )}
                { agentStatus && agentStatus?.type === 'out-bound' && ( <FeatherIcon icon="phone-outgoing" size={22} /> )}
                { !agentStatus && ( <FeatherIcon icon="phone-off" size={22} />) }
            </Link>
        </div>
    );
}

export default CallSwitchBox;
