import actions from './actions';

import { DataService } from '../../config/data/dataService';
const {  
    agentStatusDataBegin, agentStatusDataSuccess, agentStatusDataErr, 
    agentChangeStatusDataBegin, agentChangeStatusDataSuccess, agentChangeStatusDataErr, 
    agentClicktoCallStatusDataBegin, agentClicktoCallStatusDataSuccess, agentClicktoCallStatusDataErr,
    agentManualClicktoCallStatusDataBegin, agentManualClicktoCallStatusDataSuccess, agentManualClicktoCallStatusDataErr, 
    agentChangeCallStatusDataBegin, agentChangeCallStatusDataSuccess, agentChangeCallStatusDataErr, agentChangeCallStatusDataReset, 
    agentSubmitPurchaseBegin,agentSubmitPurchaseSuccess,agentSubmitPurchaseErr, 
    agentListBegin, agentListSuccess, agentListErr, 
    agentResubmitPurchaseBegin,agentResubmitPurchaseSuccess,agentResubmitPurchaseErr, 
    agentWalkoutQueriesDataBegin,agentWalkoutQueriesDataSuccess,agentWalkoutQueriesDataErr,
    agentPurchaseQueriesDataBegin,agentPurchaseQueriesDataSuccess,agentPurchaseQueriesDataErr,
    agentPurchaseEditDataBegin,agentPurchaseEditDataSuccess,agentPurchaseEditDataErr, 
    agentNumberBegin, agentNumberSuccess,agentNumberErr, 
    getDispositionRequestBegin, getDispositionRequestSuccess,getDispositionRequestErr, 
    agentSubmitRegisterBegin, agentSubmitRegisterSuccess,agentSubmitRegisterErr, 
    getRequestTypeBegin, getRequestTypeSuccess,getRequestTypeErr,  
    requestSubmittedListBegin, requestSubmittedListSuccess, requestSubmittedListErr,
    requestSubmittedDetailBegin, requestSubmittedDetailSuccess, requestSubmittedDetailErr, 
    happyComplaintListBegin, happyComplaintListSuccess,  happyComplaintListErr, 
    updateComplaintIDBegin,updateComplaintIDSuccess,updateComplaintIDErr,updateComplaintIDReset,
    updatePriorityComplaintIDBegin,updatePriorityComplaintIDSuccess,updatePriorityComplaintIDErr,updatePriorityComplaintIDReset,
    updatePriorityToScheduledBegin,updatePriorityToScheduledSuccess,updatePriorityToScheduledErr,updatePriorityToScheduledReset,
    getRegisterDispositionBegin,getRegisterDispositionSuccess,getRegisterDispositionErr,
    updateRegisterStatusBegin,updateRegisterStatusSuccess,updateRegisterStatusErr,updateRegisterStatusReset,
    getPriorityListBegin,getPriorityListSuccess,getPriorityListErr,
    getScheduleListBegin,getScheduleListSuccess, getScheduleListErr, 
    getLegalScheduleListBegin,getLegalScheduleListSuccess,getLegalScheduleListErr,
    getDemoRegisterListBegin,getDemoRegisterListSuccess, getDemoRegisterListErr, 
    abandonedCartListBegin,abandonedCartListSuccess,abandonedCartListErr,
    getAbandonedDispositionBegin,getAbandonedDispositionSuccess,getAbandonedDispositionErr,
    updateAbandonedBegin,updateAbandonedSuccess,updateAbandonedErr,updateAbandonedReset,

    mandatoryServiceListBegin,mandatoryServiceListSuccess,mandatoryServiceListErr,
    getMandatoryDispositionBegin,getMandatoryDispositionSuccess,getMandatoryDispositionErr,
    updateMandatoryBegin,updateMandatorySuccess,updateMandatoryErr,updateMandatoryReset,

    accessoriesTeleSalesListBegin,accessoriesTeleSalesListSuccess,accessoriesTeleSalesListErr,
    getAccessoriesTeleSalesDispositionBegin,getAccessoriesTeleSalesDispositionSuccess,getAccessoriesTeleSalesDispositionErr,
    updateAccessoriesTeleSalesBegin,updateAccessoriesTeleSalesSuccess,updateAccessoriesTeleSalesErr,updateAccessoriesTeleSalesReset,

    demoHappySapAgentListBegin, demoHappySapAgentListSuccess, demoHappySapAgentListErr,
    updateDemoHappySapStatusBegin, updateDemoHappySapStatusSuccess, updateDemoHappySapStatusErr,updateDemoHappySapStatusReset,
    demoHappySapAgentScheduledListBegin,demoHappySapAgentScheduledListSuccess,demoHappySapAgentScheduledListErr,
    demoHappySapAgentPriorityListBegin,demoHappySapAgentPriorityListSuccess,demoHappySapAgentPriorityListErr,

    getAgentCallPopupCustomerBegin,getAgentCallPopupCustomerSuccess,getAgentCallPopupCustomerErr, 
    getDownloadComplaintDetailsBegin, getDownloadComplaintDetailsSuccess, getDownloadComplaintDetailsErr, 
    getReferenceDataBegin, getReferenceDataSuccess, getReferenceDataErr, 
    getAgentBasedCallRecordDataBegin,getAgentBasedCallRecordDataSuccess,getAgentBasedCallRecordDataErr,
    agentReportDataBegin, agentReportDataSuccess, agentReportDataErr, 
    agentReportSaveDataBegin, agentReportSaveDataSuccess, agentReportSaveDataErr, 
    agentReportDownloadDataBegin, agentReportDownloadDataSuccess, agentReportDownloadDataErr, 
    stopTimerDataBegin,stopTimerDataSuccess,stopTimerDataErr,stopTimerDataReset,
} = actions;

// Agent Current Status
const getAgentCurrentStatus = () => {
    return async (dispatch) => {
        try {
            dispatch(agentStatusDataBegin());
            const response = await DataService.get('/getAgentCurrentStatus');
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentStatusDataSuccess(response.data.data));
                // Cookies.set('agentStatus', { agentId: 1999,type:data.callType,callStatus:data.callStatus,status:true});
            } else {
                dispatch(agentStatusDataErr());
                // Cookies.remove('agentStatus');
            }
        } catch (err) {
            dispatch(agentStatusDataErr(err));
        }
    };
}

// Agent Updated Status
const updateAgentStatus = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentChangeStatusDataBegin());
            const response = await DataService.post('/changeAgentCurrentStatus',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentChangeStatusDataSuccess(response.data.data));
                dispatch(getAgentCurrentStatus());
                // Cookies.set('agentStatus', { agentId: 1999,type:data.callType,callStatus:data.callStatus,status:true});
            } else {
                dispatch(agentChangeStatusDataErr());
                // Cookies.remove('agentStatus');
            }
        } catch (err) {
            dispatch(agentChangeStatusDataErr(err));
        }
    };
}

// Agent Status Updated
const updateAgentCallStatus = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentChangeCallStatusDataBegin());
            const response = await DataService.post('/agentCallChange',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentChangeCallStatusDataSuccess(response.data));
            } else {
                dispatch(agentChangeCallStatusDataErr());
                // Cookies.remove('agentStatus');
            }
        } catch (err) {
            dispatch(agentChangeCallStatusDataErr(err));
        }
    };
}


const resetAgentCallStatus = () => {
    return async (dispatch) => {
        dispatch(agentChangeCallStatusDataReset());
        dispatch(getAgentCurrentStatus());
    };
}

// Agent Click to Call
const agentClicktocall = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentClicktoCallStatusDataBegin());
            const response = await DataService.post('/agentClicktoCall',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentClicktoCallStatusDataSuccess(response.data.data));
                // dispatch(getAgentCurrentStatus());
                // Cookies.set('agentStatus', { agentId: 1999,type:data.callType,callStatus:data.callStatus,status:true});
            } else {
                dispatch(agentClicktoCallStatusDataErr());
                // Cookies.remove('agentStatus');
            }
        } catch (err) {
            dispatch(agentClicktoCallStatusDataErr(err));
        }
    };
}

// Agent Click to Call
const agentManualClicktocall = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentManualClicktoCallStatusDataBegin());
            const response = await DataService.post('/agentManualClicktoCall',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentManualClicktoCallStatusDataSuccess(response.data.data));
            } else {
                dispatch(agentManualClicktoCallStatusDataErr());
                // Cookies.remove('agentStatus');
            }
        } catch (err) {
            dispatch(agentManualClicktoCallStatusDataErr(err));
        }
    };
}

// Get All Agent List
const getAgentList = () => {
    return async (dispatch) => {
        try {
            dispatch(agentListBegin());
            const response = await DataService.get('/getAgentList');
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentListSuccess(response.data.data));
            } else {
                dispatch(agentListErr());
            }
        } catch (err) {
            dispatch(agentListErr(err));
        }
    };
}

// Submit Purchase Enquiry
const submitPurchaseEnquiry = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(agentSubmitPurchaseBegin());
            const response = await DataService.post('/submitPurchaseEnquiry',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentSubmitPurchaseSuccess(response.data));
            } else {
                dispatch(agentSubmitPurchaseErr());
            }
        } catch (err) {
            dispatch(agentSubmitPurchaseErr(err));
        }
    };
} 

// Edit Submit Purchase Enquiry
const editSubmitPurchaseEnquiry = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(agentSubmitPurchaseBegin());
            const response = await DataService.post('/editSubmitPurchaseEnquiry',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentSubmitPurchaseSuccess(response.data));
            } else {
                dispatch(agentSubmitPurchaseErr());
            }
        } catch (err) {
            dispatch(agentSubmitPurchaseErr(err));
        }
    };
} 

// Resubmit Purchase Enquiry
const reSubmitPurchaseEnquiry = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(agentResubmitPurchaseBegin());
            const response = await DataService.post('/purchaseEnquiryAlreadyExists',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentResubmitPurchaseSuccess(response.data.data));
                dispatch(agentSubmitPurchaseSuccess());
            } else {
                dispatch(agentResubmitPurchaseErr());
            }
        } catch (err) {
            dispatch(agentResubmitPurchaseErr(err));
        }
    };
} 

// Walkout Agent Read Token
const getWalkoutAgentReadToken = (data) => {
    return async (dispatch) => {
        try {
            const response = await DataService.post('/getWalkoutAgentReadToken',data);
            console.log(response);
        } catch (err) {
        }
    };
};

// Agent Walkout List
const agentBasedWalkoutList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentWalkoutQueriesDataBegin());
            const response = await DataService.post('/agentBasedWalkoutList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentWalkoutQueriesDataSuccess(response.data.data));
            } else {
                dispatch(agentWalkoutQueriesDataErr());
            }
        } catch (err) {
            dispatch(agentWalkoutQueriesDataErr(err));
        }
    };
}

// Agent Purchase List
const agentBasedPurchaseList = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentPurchaseQueriesDataBegin());
            const response = await DataService.post('/getAllAgentPurchaseEnquiry',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentPurchaseQueriesDataSuccess(response.data.data));
            } else {
                dispatch(agentPurchaseQueriesDataErr());
            }
        } catch (err) {
            dispatch(agentPurchaseQueriesDataErr(err));
        }
    };
}

// Agent Purchase Edit Enquiry
const agenteditPurchaseEnquiry = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentPurchaseEditDataBegin());
            const response = await DataService.post('/editPurchaseEnquiry',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentPurchaseEditDataSuccess(response.data.data));
            } else {
                dispatch(agentPurchaseEditDataErr());
            }
        } catch (err) {
            dispatch(agentPurchaseEditDataErr(err));
        }
    };
}

// Agent Assign Mobile Number 
const getAgentNumber = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentNumberBegin());
            const response = await DataService.get('/getAgentNumber');
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentNumberSuccess(response.data.data));
            } else {
                dispatch(agentNumberErr());
            }
        } catch (err) {
            dispatch(agentNumberErr(err));
        }
    };
}

// getDispositionRequest Type
const getDispositionRequest = (data) => {
    return async (dispatch) => {
        try {
            dispatch(getDispositionRequestBegin());
            const response = await DataService.get('/getDispositionRequest');
            if (response.status === 200 && response.data.status === true) {
                dispatch(getDispositionRequestSuccess(response.data.data.object_list));
            } else {
                dispatch(getDispositionRequestErr());
            }
        } catch (err) {
            dispatch(getDispositionRequestErr(err));
        }
    };
}

// Submit Purchase Enquiry
const submitComplaintRegisterEnquiry = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(agentSubmitRegisterBegin());
            const response = await DataService.post('/submitComplaintRegisterEnquiry',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentSubmitRegisterSuccess(response.data));
            } else {
                dispatch(agentSubmitRegisterErr());
            }
        } catch (err) {
            dispatch(agentSubmitRegisterErr(err));
        }
    };
} 

// Edit Complaint Register Enquriy
const editSubmitComplaintRegisterEnquiry = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(agentSubmitRegisterBegin());
            const response = await DataService.post('/editComplaintRegisterEnquiry',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentSubmitRegisterSuccess(response.data));
            } else {
                dispatch(agentSubmitRegisterErr());
            }
        } catch (err) {
            dispatch(agentSubmitRegisterErr(err));
        }
    };
} 

// Get ALL Request Type
const getRequestTypes = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(getRequestTypeBegin());
            const response = await DataService.get('/getRequestTypes',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getRequestTypeSuccess(response.data.data));
            } else {
                dispatch(getRequestTypeErr());
            }
        } catch (err) {
            dispatch(getRequestTypeErr(err));
        }
    };
}

// Get ALL Request Type
const requestTypeSubmittedList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(requestSubmittedListBegin());
            const response = await DataService.post('/requestTypeSubmittedList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(requestSubmittedListSuccess(response.data.data));
            } else {
                dispatch(requestSubmittedListErr());
            }
        } catch (err) {
            dispatch(requestSubmittedListErr(err));
        }
    };
}

// Single View Complaint Details 
const requestTypeSubmittedDetails = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(requestSubmittedDetailBegin());
            const response = await DataService.post('/requestTypeSubmittedDetails',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(requestSubmittedDetailSuccess(response.data.data));
            } else {
                dispatch(requestSubmittedDetailErr());
            }
        } catch (err) {
            dispatch(requestSubmittedDetailErr(err));
        }
    };
} 

//  Happy Call Details List
const happydemoComplaintList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(happyComplaintListBegin());
            const response = await DataService.post('/happydemoComplaintList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(happyComplaintListSuccess(response.data.data));
            } else {
                dispatch(happyComplaintListErr());
            }
        } catch (err) {
            dispatch(happyComplaintListErr(err));
        }
    };
} 

// Update Complaint ID
const updateComplaintID = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(updateComplaintIDBegin());
            const response = await DataService.post('/updateComplaintID',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(updateComplaintIDSuccess(response.data.data));
            } else {
                dispatch(updateComplaintIDErr());
            }
        } catch (err) {
            dispatch(updateComplaintIDErr(err));
        }
    };
} 

//Reset Complaint ID Status
const resetAgentComplaintID = () =>{
    return async (dispatch) => {
        dispatch(updateComplaintIDReset());
    };
} 

// Update Priority Complaint ID
const updatePriorityComplaintID = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(updatePriorityComplaintIDBegin());
            const response = await DataService.post('/updatePriorityComplaintID',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(updatePriorityComplaintIDSuccess(response.data.data));
            } else {
                dispatch(updatePriorityComplaintIDErr());
            }
        } catch (err) {
            dispatch(updatePriorityComplaintIDErr(err));
        }
    };
} 

//Reset Priority Complaint ID Status
const resetPriorityAgentComplaintID = () =>{
    return async (dispatch) => {
        dispatch(updatePriorityComplaintIDReset());
    };
} 

// Update Priority To Scheduled
const updatePriorityToScheduled = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(updatePriorityToScheduledBegin());
            const response = await DataService.post('/updatePriorityToScheduled',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(updatePriorityToScheduledSuccess(response.data.data));
            } else {
                dispatch(updatePriorityToScheduledErr());
            }
        } catch (err) {
            dispatch(updatePriorityToScheduledErr(err));
        }
    };
} 

//Reset Priority To Scheduled
const resetPriorityToScheduled = () =>{
    return async (dispatch) => {
        dispatch(updatePriorityToScheduledReset());
    };
} 

// Get Register Complaint Disposition
const getRegisterDisposition = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(getRegisterDispositionBegin());
            const response = await DataService.post('/getRegisterDisposition',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getRegisterDispositionSuccess(response.data.data));
            } else {
                dispatch(getRegisterDispositionErr());
            }
        } catch (err) {
            dispatch(getRegisterDispositionErr(err));
        }
    };
} 

//Update Register Complaint Status
const updateRegisterStatus = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(updateRegisterStatusBegin());
            const response = await DataService.post('/updateRegisterStatus',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(updateRegisterStatusSuccess(response.data.data));
            } else {
                dispatch(updateRegisterStatusErr());
            }
        } catch (err) {
            dispatch(updateRegisterStatusErr(err));
        }
    };
} 

//Reset Status Status
const resetAgentComplaintStatus = () =>{
    return async (dispatch) => {
        dispatch(updateRegisterStatusReset());
    };
} 

// Get Priority List
const getPriorityList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(getPriorityListBegin());
            const response = await DataService.post('/getPriorityList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getPriorityListSuccess(response.data.data));
            } else {
                dispatch(getPriorityListErr());
            }
        } catch (err) {
            dispatch(getPriorityListErr(err));
        }
    };
} 

// Get Schedule List
const getScheduleList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(getScheduleListBegin());
            const response = await DataService.post('/getScheduleList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getScheduleListSuccess(response.data.data));
            } else {
                dispatch(getScheduleListErr());
            }
        } catch (err) {
            dispatch(getScheduleListErr(err));
        }
    };
} 

// Get Legal Schedule
const getLegalScheduleList =(data) => {
    return async (dispatch) => {
        try {
            dispatch(getLegalScheduleListBegin());
            const response = await DataService.post('/getLegalScheduleList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getLegalScheduleListSuccess(response.data.data));
            } else {
                dispatch(getLegalScheduleListErr());
            }
        } catch (err) {
            dispatch(getLegalScheduleListErr(err));
        }
    };
} 

// Get ALL Demo Register
const getDemoRegisterList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(getDemoRegisterListBegin());
            const response = await DataService.post('/getDemoRegisterList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getDemoRegisterListSuccess(response.data.data));
            } else {
                dispatch(getDemoRegisterListErr());
            }
        } catch (err) {
            dispatch(getDemoRegisterListErr(err));
        }
    };
}

// Abandoned Cart List
const abandonedCartList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(abandonedCartListBegin());
            const response = await DataService.post('/getAbandonedList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(abandonedCartListSuccess(response.data.data));
            } else {
                dispatch(abandonedCartListErr());
            }
        } catch (err) {
            dispatch(abandonedCartListErr(err));
        }
    };
} 

// Abandoned Disposition
const getAbandonedDisposition = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(getAbandonedDispositionBegin());
            const response = await DataService.post('/getAbandonedDisposition',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getAbandonedDispositionSuccess(response.data.data));
            } else {
                dispatch(getAbandonedDispositionErr());
            }
        } catch (err) {
            dispatch(getAbandonedDispositionErr(err));
        }
    };
} 

// Update Abandoned 
const updateAbandonedStatus = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(updateAbandonedBegin());
            const response = await DataService.post('/updateAbandonedStatus',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(updateAbandonedSuccess(response.data.data));
            } else {
                dispatch(updateAbandonedErr());
            }
        } catch (err) {
            dispatch(updateAbandonedErr(err));
        }
    };
} 

// Reset Mandatory 
const resetAbandonedStatus = () =>{
    return async (dispatch) => {
        dispatch(updateAbandonedReset());
    };
} 

//  Pai Mandatory Service List
const mandatoryServiceList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(mandatoryServiceListBegin());
            const response = await DataService.post('/getMandatoryList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(mandatoryServiceListSuccess(response.data.data));
            } else {
                dispatch(mandatoryServiceListErr());
            }
        } catch (err) {
            dispatch(mandatoryServiceListErr(err));
        }
    };
} 

// Mandatory Disposition
const getMandatoryDisposition = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(getMandatoryDispositionBegin());
            const response = await DataService.post('/getMandatoryDisposition',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getMandatoryDispositionSuccess(response.data.data));
            } else {
                dispatch(getMandatoryDispositionErr());
            }
        } catch (err) {
            dispatch(getMandatoryDispositionErr(err));
        }
    };
} 

// Update Mandatory 
const updateMandatoryStatus = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(updateMandatoryBegin());
            const response = await DataService.post('/updateMandatoryStatus', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(updateMandatorySuccess(response.data.data));
            } else {
                dispatch(updateMandatoryErr());
            }
        } catch (err) {
            dispatch(updateMandatoryErr(err));
        }
    };
} 

// Reset Mandatory 
const resetMandatoryStatus = () =>{
    return async (dispatch) => {
        dispatch(updateMandatoryReset());
    };
} 

//  Pai Accessories Tele Sales Service List
const accessoriesTeleSalesList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(accessoriesTeleSalesListBegin());
            const response = await DataService.post('/getAccessoriesTeleSalesList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(accessoriesTeleSalesListSuccess(response.data.data));
            } else {
                dispatch(accessoriesTeleSalesListErr());
            }
        } catch (err) {
            dispatch(accessoriesTeleSalesListErr(err));
        }
    };
} 

// Accessories Tele Sales Disposition
const getAccessoriesTeleSalesDisposition = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(getAccessoriesTeleSalesDispositionBegin());
            const response = await DataService.post('/getAccessoriesTeleSalesDisposition',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getAccessoriesTeleSalesDispositionSuccess(response.data.data));
            } else {
                dispatch(getAccessoriesTeleSalesDispositionErr());
            }
        } catch (err) {
            dispatch(getAccessoriesTeleSalesDispositionErr(err));
        }
    };
} 

// Update Accessories Tele Sales 
const updateAccessoriesTeleSalesStatus = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(updateAccessoriesTeleSalesBegin());
            const response = await DataService.post('/updateAccessoriesTeleSalesStatus', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(updateAccessoriesTeleSalesSuccess(response.data.data));
            } else {
                dispatch(updateAccessoriesTeleSalesErr());
            }
        } catch (err) {
            dispatch(updateAccessoriesTeleSalesErr(err));
        }
    };
} 

// Reset Accessories Tele Sales 
const resetAccessoriesTeleSalesStatus = () =>{
    return async (dispatch) => {
        dispatch(updateAccessoriesTeleSalesReset());
    };
} 


//  Demo Happy Sap Agent List
const demoHappySapAgentList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(demoHappySapAgentListBegin());
            const response = await DataService.post('/getDemoHappySapList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(demoHappySapAgentListSuccess(response.data.data));
            } else {
                dispatch(demoHappySapAgentListErr());
            }
        } catch (err) {
            dispatch(demoHappySapAgentListErr(err));
        }
    };
} 

// Update Demo Happy Sap Status
const updateDemoHappySapStatus = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(updateDemoHappySapStatusBegin());
            const response = await DataService.post('/updateDemoHappySapStatus', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(updateDemoHappySapStatusSuccess(response.data.data));
            } else {
                dispatch(updateDemoHappySapStatusErr());
            }
        } catch (err) {
            dispatch(updateDemoHappySapStatusErr(err));
        }
    };
} 

// Reset Demo Happy Sap
const resetDemoHappySapStatus = () =>{
    return async (dispatch) => {
        dispatch(updateDemoHappySapStatusReset());
    };
} 

//  Demo Happy Sap Agent Scheduled List
const demoHappySapAgentScheduledList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(demoHappySapAgentScheduledListBegin());
            const response = await DataService.post('/getDemoHappySapScheduledList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(demoHappySapAgentScheduledListSuccess(response.data.data));
            } else {
                dispatch(demoHappySapAgentScheduledListErr());
            }
        } catch (err) {
            dispatch(demoHappySapAgentScheduledListErr(err));
        }
    };
} 

//  Demo Happy Sap Agent Priority List
const demoHappySapAgentPriorityList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(demoHappySapAgentPriorityListBegin());
            const response = await DataService.post('/getDemoHappySapPriorityList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(demoHappySapAgentPriorityListSuccess(response.data.data));
            } else {
                dispatch(demoHappySapAgentPriorityListErr());
            }
        } catch (err) {
            dispatch(demoHappySapAgentPriorityListErr(err));
        }
    };
} 

// Auto Popup
const getAgentCallPopupCustomer = () => {
    return async (dispatch) => {
        try {
            dispatch(getAgentCallPopupCustomerBegin());
            const response = await DataService.get('/getAgentCallPopupCustomer');
            if (response.status === 200 && response.data.status === true) {
                dispatch(getAgentCallPopupCustomerSuccess(response.data.data));
            } else {
                dispatch(getAgentCallPopupCustomerErr());
            }
        } catch (err) {
            dispatch(getAgentCallPopupCustomerErr(err));
        }
    };
}

// Download Customer Details
const getDownloadComplaintDetails = (data) => {
    return async (dispatch) => {
        try {
            dispatch(getDownloadComplaintDetailsBegin());
            const response = await DataService.post('/getDownloadComplaintDetails',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getDownloadComplaintDetailsSuccess(response.data.data));
                window.open(response.data.data.url, '_blank');
            } else {
                dispatch(getDownloadComplaintDetailsErr());
            }
        } catch (err) {
            dispatch(getDownloadComplaintDetailsErr(err));
        }
    };
}

// Download Sales Customer Details
const getDownloadSalesDetails = (data) => {
    return async (dispatch) => {
        try {
            dispatch(getDownloadComplaintDetailsBegin());
            const response = await DataService.post('/getDownloadSalesDetails',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getDownloadComplaintDetailsSuccess(response.data.data));
                window.open(response.data.data.url, '_blank');
            } else {
                dispatch(getDownloadComplaintDetailsErr());
            }
        } catch (err) {
            dispatch(getDownloadComplaintDetailsErr(err));
        }
    };
}

// Search Reference
const getReferenceDetails = (data) => {
    return async (dispatch) => {
        try {
            dispatch(getReferenceDataBegin());
            const response = await DataService.post('/getReferenceDetails', data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(getReferenceDataSuccess(response.data.data));
                console.log(response.data.data);
            } else {
                dispatch(getReferenceDataErr());
            }
        } catch (err) {
            dispatch(getReferenceDataErr(err));
        }
    };
}

// Agent Based Call Record
const getAgentBasedCallRecord = () => {
    return async (dispatch) => {
        try {
            dispatch(getAgentBasedCallRecordDataBegin());
            const response = await DataService.get('/getAgentBasedCallRecord');
            if (response.status === 200 && response.data.status === true) {
                dispatch(getAgentBasedCallRecordDataSuccess(response.data.data));
            } else {
                dispatch(getAgentBasedCallRecordDataErr());
            }
        } catch (err) {
            dispatch(getAgentBasedCallRecordDataErr(err));
        }
    };
}

// Agent Report
const getAgentReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentReportDataBegin());
            const response = await DataService.post('/getAgentReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentReportDataSuccess(response.data.data));
            } else {
                dispatch(agentReportDataErr());
            }
        } catch (err) {
            dispatch(agentReportDataErr(err));
        }
    };
};

// Agent Save Report
const getAgentReportSave = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentReportSaveDataBegin());
            const response = await DataService.post('/getAgentReportSave',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentReportSaveDataSuccess(response.data.data));
            } else {
                dispatch(agentReportSaveDataErr());
            }
        } catch (err) {
            dispatch(agentReportSaveDataErr(err));
        }
    };
};

// Agent Download Report
const getAgentReportDownload = (data) => {
    return async (dispatch) => {
        try {
            dispatch(agentReportDownloadDataBegin());
            const response = await DataService.post('/getAgentReportDownload',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(agentReportDownloadDataSuccess(response.data.data));
            } else {
                dispatch(agentReportDownloadDataErr());
            }
        } catch (err) {
            dispatch(agentReportDownloadDataErr(err));
        }
    };
};

// Stop Timer
const clearStopTimer = (data) => {
    
    return async (dispatch) => {
        try {
            dispatch(stopTimerDataBegin());
            const response = await DataService.post('/stopCallStatus',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(stopTimerDataSuccess(response.data.data));
            } else {
                dispatch(stopTimerDataErr());
            }
        } catch (err) {
            dispatch(stopTimerDataErr(err));
        }
    };
}

// Reset Timer
const resetStopTimer = (data) => {
    return async (dispatch) => {
        dispatch(stopTimerDataReset());
    };
}



export { 
    getAgentCurrentStatus, updateAgentStatus, updateAgentCallStatus,  resetAgentCallStatus,
    agentClicktocall, agentManualClicktocall, 
    submitPurchaseEnquiry, editSubmitPurchaseEnquiry, reSubmitPurchaseEnquiry, 
    getAgentList , 
    getWalkoutAgentReadToken, agentBasedWalkoutList, 
    agentBasedPurchaseList, agenteditPurchaseEnquiry, 
    getAgentNumber, 
    getDispositionRequest, submitComplaintRegisterEnquiry, editSubmitComplaintRegisterEnquiry,
    getRequestTypes, 
    requestTypeSubmittedList, requestTypeSubmittedDetails, resetAgentComplaintStatus, 
    happydemoComplaintList, 
    updateComplaintID, resetAgentComplaintID, 
    updatePriorityComplaintID,resetPriorityAgentComplaintID,
    updatePriorityToScheduled,resetPriorityToScheduled,
    getRegisterDisposition, updateRegisterStatus, 
    getPriorityList, getScheduleList, getLegalScheduleList,
    abandonedCartList, getAbandonedDisposition,  updateAbandonedStatus, resetAbandonedStatus,
    mandatoryServiceList, getMandatoryDisposition, updateMandatoryStatus, resetMandatoryStatus,
    accessoriesTeleSalesList, getAccessoriesTeleSalesDisposition, updateAccessoriesTeleSalesStatus, resetAccessoriesTeleSalesStatus,
    demoHappySapAgentList, updateDemoHappySapStatus, resetDemoHappySapStatus, demoHappySapAgentScheduledList,demoHappySapAgentPriorityList,
    getAgentCallPopupCustomer, getDownloadComplaintDetails, getDownloadSalesDetails, 
    getReferenceDetails, getAgentBasedCallRecord, getDemoRegisterList, 
    getAgentReport, getAgentReportSave, getAgentReportDownload,
    clearStopTimer, resetStopTimer,
};
