import React, { lazy } from 'react';
import { Switch, Route,Redirect ,useRouteMatch} from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard                 = lazy(() => import('../../container/datamanagement/Dashboard'));

const FollowLegal               = lazy(() => import('../../container/datamanagement/FollowLegal'));
const ViewFollowDetails         = lazy(() => import('../../container/datamanagement/ViewFollowDetails'));

const Report                    = lazy(() => import('../../container/datamanagement/NewReport'));
const DownloadReport            = lazy(() => import('../../container/datamanagement/DownloadReport'));


function NotFound() {
    return <Redirect to="/admin" />;
}

function OutOfWarrantyRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`} component={Dashboard} />
			<Route exact path={`${path}/dashboard`} component={Dashboard} />

            <Route exact path={`${path}/legal_case_log`} component={FollowLegal} />
            <Route exact path={`${path}/legal_case_log/view`} component={ViewFollowDetails} />

            <Route exact path={`${path}/report_register`} component={Report} />
            <Route exact path={`${path}/report_happy`} component={Report} />
            <Route exact path={`${path}/report_follow`} component={Report} />
            <Route exact path={`${path}/report_tele`} component={Report} />


            <Route exact path={`${path}/available_report`} component={DownloadReport} />
            <Route path="*" exact={true} component={NotFound} />
        </Switch>
    );
}

export default withAdminLayout(OutOfWarrantyRoutes);