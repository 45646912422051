import actions from './actions';
import { DataService } from '../../config/data/dataService';
const { 
    employeeDataBegin, employeeDataSuccess, employeeDataErr, 
    employeePermissionDataBegin,employeePermissionDataSuccess,employeePermissionDataErr, employeePermissionDataReset,
    employeeLoginStatusBegin,employeeLoginStatusSuccess,employeeLoginStatusErr, employeeLoginStatusReset,
    employeeStatusBegin, employeeStatusSuccess, employeeStatusErr, 
    employeePasswordBegin, employeePasswordSuccess, employeePasswordErr,employeePasswordReset, 
    employeeDashboardDataBegin,employeeDashboardDataSuccess,employeeDashboardDataErr,
    employeeReportDataBegin, employeeReportDataSuccess, employeeReportDataErr, 
    employeeReportSaveDataBegin, employeeReportSaveDataSuccess, employeeReportSaveDataErr, 
    employeeReportDownloadDataBegin, employeeReportDownloadDataSuccess, employeeReportDownloadDataErr, 
    employeeSyncDataBegin, employeeSyncDataSuccess, employeeSyncDataErr, 
    employeeCustomStatusBegin,employeeCustomStatusSuccess,employeeCustomStatusErr,employeeCustomStatusReset,
    editCustomEmployeeStatusBegin, editCustomEmployeeStatusSuccess, editCustomEmployeeStatusErr, editCustomEmployeeStatusReset,
    vendorPincodeBegin,vendorPincodeSuccess,vendorPincodeErr,
} = actions;

const employeeFetchData = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeDataBegin());
            const response = await DataService.post('/getAllEmployee',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeDataSuccess(response.data.data));
            } else {
                dispatch(employeeDataErr());
            }
        } catch (err) {
            dispatch(employeeDataErr(err));
        }
    };
};

const updateEmployeeStatus = (data) =>{
    const modifiedData = { checked:data.status,id:data.employee_id};
    return async (dispatch) => {
        try {
            dispatch(employeeStatusBegin());
            const response = await DataService.post('/updateEmployeeStatus',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeStatusSuccess(modifiedData));
            } else {
                dispatch(employeeStatusErr());
            }
        } catch (err) {
            dispatch(employeeStatusErr(err));
        }
    };
} 

const updateUserType = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(employeePermissionDataBegin());
            const response = await DataService.post('/updateEmployeePermission',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeePermissionDataSuccess(response.data.data));
            } else {
                dispatch(employeePermissionDataErr());
            }
        } catch (err) {
            dispatch(employeePermissionDataErr(err));
        }
    };
} 

const resetUserType = () =>{
    return async (dispatch) => {
        dispatch(employeePermissionDataReset());
    };
} 

const updateEmployeeLoginStatus = (data) => {
    const modifiedData = { checked:data.is_login_status,id:data.employee_id};

    return async (dispatch) => {
        try {
            dispatch(employeeLoginStatusBegin());
            const response = await DataService.post('/updateEmployeeLoginStatus',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeLoginStatusSuccess(modifiedData));
            } else {
                dispatch(employeeLoginStatusErr());
            }
        } catch (err) {
            dispatch(employeeLoginStatusErr(err));
        }
    };
} 

const resetEmployeeLoginStatus = (data) => {
    return async (dispatch) => {
        dispatch(employeeLoginStatusReset());
    };
} 

const updateEmployeePassword = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(employeePasswordBegin());
            const response = await DataService.post('/updateEmployeePassword',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeePasswordSuccess(response.data.data));
            } else {
                dispatch(employeePasswordErr());
            }
        } catch (err) {
            dispatch(employeePasswordErr(err));
        }
    };
} 

const resetEmployeePassword = (data) =>{
    return async (dispatch) => {
        dispatch(employeePasswordReset());
    };
} 

const getEmployeeDashboard = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeDashboardDataBegin());
            const response = await DataService.post('/dashboardGetEmployee',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeDashboardDataSuccess(response.data.data,response.data.start,response.data.end));
            } else {
                dispatch(employeeDashboardDataErr());
            }
        } catch (err) {
            dispatch(employeeDashboardDataErr(err));
        }
    };
};

const getEmployeeReport = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeReportDataBegin());
            const response = await DataService.post('/getEmployeeReport',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeReportDataSuccess(response.data.data));
            } else {
                dispatch(employeeReportDataErr());
            }
        } catch (err) {
            dispatch(employeeReportDataErr(err));
        }
    };
};

const getEmployeeReportSave = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeReportSaveDataBegin());
            const response = await DataService.post('/getEmployeeReportSave',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeReportSaveDataSuccess(response.data.data));
            } else {
                dispatch(employeeReportSaveDataErr());
            }
        } catch (err) {
            dispatch(employeeReportSaveDataErr(err));
        }
    };
};

const getEmployeeReportDownload = (data) => {
    return async (dispatch) => {
        try {
            dispatch(employeeReportDownloadDataBegin());
            const response = await DataService.post('/getEmployeeReportDownload',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeReportDownloadDataSuccess(response.data.data));
            } else {
                dispatch(employeeReportDownloadDataErr());
            }
        } catch (err) {
            dispatch(employeeReportDownloadDataErr(err));
        }
    };
};

const manualEmployeeSync = () => {
    return async (dispatch) => {
        try {
            dispatch(employeeSyncDataBegin());
            const response = await DataService.get('/getManulaSyncEmployee');
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeSyncDataSuccess(response.data.data));
            } else {
                dispatch(employeeSyncDataErr());
            }
        } catch (err) {
            dispatch(employeeSyncDataErr(err));
        }
    };
}

const createCustomEmployee = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(employeeCustomStatusBegin());
            const response = await DataService.post('/createCustomEmployee',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(employeeCustomStatusSuccess(response.data.data));
            } else {
                dispatch(employeeCustomStatusErr());
            }
        } catch (err) {
            dispatch(employeeCustomStatusErr(err));
        }
    };
} 

const resetCustomEmployee = () =>{
    return async (dispatch) => {
        dispatch(employeeCustomStatusReset());
    }
}

const editCustomEmployeeDetails = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(editCustomEmployeeStatusBegin());
            const response = await DataService.post('/editCustomEmployee',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(editCustomEmployeeStatusSuccess(response.data.data));
            } else {
                dispatch(editCustomEmployeeStatusErr());
            }
        } catch (err) {
            dispatch(editCustomEmployeeStatusErr(err));
        }
    };
} 

const resetEditCustomEmployeeDetails = (data) =>{
    return async (dispatch) => {
        dispatch(editCustomEmployeeStatusReset());
    };
} 

const vendorPincodeList = (data) =>{
    return async (dispatch) => {
        try {
            dispatch(vendorPincodeBegin());
            const response = await DataService.post('/vendorPincodeList',data);
            if (response.status === 200 && response.data.status === true) {
                dispatch(vendorPincodeSuccess(response.data.data));
            } else {
                dispatch(vendorPincodeErr());
            }
        } catch (err) {
            dispatch(vendorPincodeErr(err));
        }
    };
} 


export { 
    getEmployeeDashboard, employeeFetchData, 
    updateUserType, resetUserType,
    updateEmployeeStatus,
    updateEmployeeLoginStatus, resetEmployeeLoginStatus,
    updateEmployeePassword, resetEmployeePassword,
    getEmployeeReport, getEmployeeReportSave, getEmployeeReportDownload, 
    manualEmployeeSync, 
    createCustomEmployee,resetCustomEmployee, editCustomEmployeeDetails, resetEditCustomEmployeeDetails,
    vendorPincodeList,
}; //filterByStatus, sortingByCategory
